export const spanishLangData = {
  MEDICAL_INFO_SYSTEM: 'Sistema de información médica',
  ACTIVE_EVENTS: 'Eventos activos',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Seleccione un evento para navegar por las usuarios.',
  BACK_TO_THE_EVENT_SELECTION: 'Volver a la selección del evento.',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Si olvidaste tu contraseña',
  LOGIN: 'Iniciar sesión',
  RESET_IT_HERE: 'restablecerlo aquí',
  CHANGE_LANGUAGE_TO: 'Cambiar idioma a',
  TEST_SYSTEM: 'SISTEMA DE PRUEBA',
  HELP: 'ayuda',
  THIS_IS_TEST_ENV: 'Este es un entorno de prueba, si desea insertar una consulta real vaya a',
  USER_OR_PASS_IS_INCORRECT: 'Nombre de usuario o contraseña incorrecta',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'No encontramos lo que estabas buscando',
  VIDEO_TUTORIALS: 'Tutoriales en vídeo',
  STILL_DIDNT_FIND: 'Aún no encontré lo que buscas,',
  ASK_THE_SUPPORT_TEAM: 'Pregunta al equipo de soporte',
  LOGGED_IN_AS: 'Conectado como',
  MY_PROFILE: 'Mi perfil',
  LOGOUT: 'Cerrar sesión',
  RETRIEVING_YOUR_ACCESS_INFO: 'Recuperando su información de acceso',
  LOGGED_IN_CAN_CLOSE: 'Iniciando sesión, ahora puede cerrar esta pestaña',
  TOKEN_HAS_NOT_BEEN_SAVED:
    'El token no se ha guardado correctamente, póngase en contacto con el soporte',
  PASSWORD_RESET: 'Restablecimiento de contraseña',
  RESET_PASSWORD: 'Restablecer la contraseña',
  INSERT_YOUR_EMAIL_TO_RESET_PASS:
    'Por favor inserte su correo electrónico para restablecer su contraseña',
  CANCEL: 'Cancelar',
  CANCEL_UPPERCASE: 'CANCELAR',
  RETURN_TO_LOGIN: 'Regresar al inicio de sesión',
  USERNAME: 'Nombre de usuario',
  USERNAME_IS_REQUIRED: 'Se requiere nombre de usuario',
  PASSWORD: 'Contraseña',
  PASSWORD_IS_REQUIRED: 'se requiere contraseña',
  FIRST_NAME: 'Nombre de pila',
  LAST_NAME: 'Apellido',
  REGISTER: 'Registrarse',
  LOADING: 'Cargando',
  INSERT_NEW_PASS: 'Por favor inserte su nueva contraseña',
  VERIFYING_TOKEN: 'Token de verificación',
  PASSWORD_DO_NOT_MATCH: 'Las contraseñas no coinciden',
  PASS_RESET_SUCCESSFUL: 'Restablecimiento de contraseña exitosa',
  SAVE_NEW_PASS: 'Guardar nueva contraseña',
  GO_TO_INBOX: 'Ir a la bandeja de entrada',
  SELECT_ALL: 'Seleccionar todo',
  DESELECT_ALL: 'Deseleccionar todo',
  DO_NOT_SHOW_AGAIN: 'No mostrar de nuevo',
  INQUIRY: 'Investigación',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Archivos adjuntos con esta consulta',
  USER_DETAILS: 'Detalles de usuario',
  CLOSE: 'Cerca',
  TEAMS: 'Equipos',
  TEAM: 'Equipo',
  SPECIALIZATION_IS: 'La especialización es',
  REQUIRED: 'necesaria',
  RESULTS_PER_PAGE: 'resultados por página',
  RESULTS_FOUND: 'Resultados encontrados',
  EXPORT: 'Exportar',
  PERSONAL_SETTINGS: 'Configuraciones personales',
  TEAM_SETTINGS: 'Configuraciones del equipo',
  USER_SETTINGS_OF: 'AJUSTES DE USUARIO DE',
  SET_TEMPLATE: 'Establecer plantilla',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Las notificaciones se sobrescribirán con las actuales',
  PREVIEW: 'Avance',
  NOTIFICATIONS: 'NOTIFICACIONES',
  ROLE: 'PAPEL',
  TEMPLATE_NAME: 'NOMBRE DE LA PLANTILLA',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Guardar este usuario como plantilla',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Agregue una plantilla a su usuario y copie el rol y las preferencias para las notificaciones',
  AUTH_AND_NOTIFY_TEMPLATE: 'Plantilla de Autorización y Notificaciones',
  MY_ROLES: 'Mis roles',
  STATUS: 'ESTADO',
  THIS_ROLE_IS_TEMP:
    'Este rol es temporal, se ha dado debido al hecho de que este usuario es un suplente',
  CREATED: 'CREADA',
  APPROVED_REJECTED: 'APROBADA / RECHAZADA',
  APPROVED_REJECTED_BY: 'APROBADO / RECHAZADO POR',
  ARCHIVE: 'ARCHIVO',
  SAVE: 'Salvar',
  IMMEDIATE_EFFECT: 'Efecto inmediato',
  OUT_OF_OFFICE_DEPUTY: 'DEPUTADO FUERA DE OFICINA',
  AUTO_ASSIGN_TO: 'ESTE EXCELENTE',
  GEOGRAPHY: 'GEOGRAFÍA',
  ALIGNMENT: 'ALINEACIÓN',
  Alignments: 'Alineaciones',
  Address: 'Habla a',
  PHONE_FAX: 'Telefono / Fax',
  Email: 'Email',
  Contacts: 'Contactos',
  WORK_INFO: 'Informacion de trabajo',
  LAST_NAME_IS: 'Apellido es',
  PERSONAL_INFO: 'Informacion personal',
  USER: 'Usuaria',
  ID: 'ID',
  UPDATED_FROM: 'actualizado desde',
  ON: 'en',
  CRM: 'CRM',
  IMPORTED_FROM: 'importado de',
  CHANGE_PASS: 'Cambia la contraseña',
  NOTIFICATION_IN_TEMPLATE: 'Notificaciones en plantilla',
  CONTINUE: 'Seguir',
  DONT_SHOW_AGAIN: 'No mostrar este mensaje nuevamente',
  USING_THE_TABS_ON_THE: 'usando las pestañas en la parte superior de la pantalla',
  PERSONAL_PROFILE_SETTINGS: 'Configuraciones de perfil personal',
  AND_YOUR: 'y tu',
  YOU_CAN_SWITCH_BETWEEN: 'Puedes cambiar entre tu',
  WELCOME_TO_YOUR_USER_PROFILE: 'Bienvenido a tu perfil de usuario',
  SAVE_THIS_USER_AS: 'Guarde esta usuario como plantilla',
  INCLUDE_NOTIFY_SETTINGS: 'Incluye configuración de notificaciones',
  INCLUDE_ROLES_SETTINGS: 'Incluye configuración de roles',
  ACTIVE_NOTIFICATIONS: 'NOTIFICACIONES ACTIVAS',
  SET_THIS_USER_AS_A_:
    'Establecer esta autorización de usuario y notificaciones como nueva plantilla',
  REQUEST_AUTH: 'Solicitar autorización',
  REQUEST: 'Solicitud',
  FEEDBACK_SUPPORT: 'Comentarios y soporte',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Si aún no puede encontrar lo que está buscando, ¡pregunte al equipo de soporte! Además, si tiene una sugerencia para futuras mejoras, este es el lugar correcto.',
  TEMPLATE: 'Modelo',
  SIGNATURE: 'Firmas',
  Disclaimers: 'Renuncias',
  Greetings: 'Saludos',
  CUSTOMIZE: 'PERSONALIZAR',
  SAVE_UPPERCASE: 'SALVAR',
  DELETE: 'ELIMINAR',
  RESET_TO_DEFAULT: 'RESTABLECEN A LOS PREDETERMINADOS',
  AFTER_SIGNATURE: 'Después de la firma',
  BEFORE_SIGNATURE: 'Antes de la firma',
  AFTER_ANSWER: 'Después de la respuesta',
  BEFORE_ANSWER: 'Antes de la respuesta',
  AFTER_GREET: 'Despues saludos',
  BEFORE_GREET: 'Antes de saludos',
  Any: 'Alguna',
  HCP: 'HCP',
  NO_HCP: 'No HCP',
  Position: 'Posición',
  ON_LABEL: 'On label',
  OFF_LABEL: 'Off label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Estás intentando personalizar la plantilla',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'No tienes permiso para hacerlo. Si desea continuar, se creará una copia de la plantilla y allí realizará los cambios.',
  CREATE_COPY: 'Crear copia',
  RECEIVED_ON: 'Recibido el',
  SENDER_EMAIL: 'Enviando correo electrónico',
  SUBJECT: 'Sujeta',
  UNSPAM: 'Unspam',
  UPLOAD: 'Subir',
  APPROVED: 'Aprobada',
  DRAFT: 'Sequía',
  SELECT_STATUS: 'Seleccionar estado',
  UPLOAD_AS: 'Subir como',
  TITLE: 'Título',
  REPOSITORY: 'Repositorio',
  BROWSE: 'Vistazo',
  URL: 'URL',
  APPROVED_ON: 'Aprobado en',
  AUTH_HOLDER: 'Titular de la autorización',
  AUTH_NUMBERS: 'Números de autorización',
  APPROVED_FOR: 'Aprobado para',
  SELECT_ONE_REGION: 'Seleccione una región',
  OWNED_BY: 'Propiedad de',
  KEYWORDS: 'Palabras clave',
  TOPICS: 'Temas',
  CATEGORIES: 'Categorias',
  PRODUCTS: 'Productos',
  PRODUCT: 'Producto',
  LANGUAGE_IS_REQUIRED: 'Se requiere lenguaje',
  LANGUAGE: 'Idioma',
  TARGET_CUSTOMERS: 'Clientes potenciales',
  TYPE_IS_REQUIRED: 'El tipo es obligatorio',
  TYPE: 'Tipo',
  TITLE_IS_REQUIRED: 'Título es requerido',
  DEFAULT_REFERENCE_IS_CREATED_:
    'La referencia predeterminada se crea con el estilo Vancouver. Si desea anular esto, ingrese la referencia completa aquí',
  REFERENCE: 'Referencia',
  DOI: 'DOI',
  PUBMED_ID: 'ID de Pubmed',
  PAGES: 'Páginas',
  ISSUE: 'Problema',
  VOLUME: 'Volumen',
  JOURNAL: 'diario',
  AUTHORS: 'Autoras',
  ABSTRACT: 'Abstracta',
  REFERENCE_IS_REQUIRED: 'Se requiere referencia',
  THIS_DOC_IS_APPROVED: 'Este documento está aprobado. Haga clic aquí para abrir el',
  ORIGINAL_DOCUMENT_AND_: 'documento original e inserte sus comentarios',
  EDIT_FILE: 'Editar archivo',
  PROPERTIES: 'Propiedades',
  OPEN: 'Abierta',
  LOCALIZED_DOCUMENTS: 'Documentos localizados',
  ORIGINAL_DOCUMENTS: 'Documento original',
  INTERNAL_APPROVAL_NUMBER: 'Número de aprobación interna',
  CREATED_BY: 'Creado por',
  EXPIRATION_DATE: 'Fecha de caducidad',
  VERSION_NUMBER: 'Número de versión',
  NO_VERSION_AVAILABLE: 'No hay versión disponible',
  ARCHIVED_IF_NO_DRAFT: 'Archivado: si no hay versiones Borrador / Aprobadas presentes.',
  APPROVED_FOR_USERS: 'Aprobado: para usuarios.',
  DRAFT_FOR_AUTHORS_: 'Borrador: para autores y editores.',
  VERSION_LEGEND: 'Versión leyenda',
  VERSION: 'versión',
  SELECT_ONE_LANG: 'Selecciona un idioma.',
  SELECT_ONE_TYPE: 'Selecciona un tipo.',
  INTERNAL_NUMBER_REQUIRED: 'Se requiere un número de aprobación interno.',
  FILE: 'Archivo',
  ATTACH_FILE: 'Adjuntar archivo',
  LITERATURE: 'Literatura',
  SLIDE_DECK: 'Conjunto de diapositivas',
  PIL: 'PIL',
  FAQ: 'Preguntas más frecuentes',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Agregar paso de aprobación',
  ADD_PARALLEL_STEP: 'Añadir paso paralelo',
  DELETE_STEP: 'Eliminar paso',
  DELETE_ALL_PARALLEL_STEPS: 'Eliminar todos los pasos paralelos',
  CHANGE_STATUS_NAME: 'Cambiar nombre de estado',
  SET_REQUIRED_ROLE: 'Establecer el rol requerido',
  SET_TEAM_MEMBER: 'Establecer miembro del equipo',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'No tiene los permisos para cambiar este proceso.',
  SAVE_ROLE: 'AHORRE PAPEL',
  CREATE_NEW_WORKFLOW: 'CREAR UN NUEVO FLUJO DE TRABAJO',
  CREATE_NEW_STEP: 'CREAR NUEVO PASO',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Ingrese el número de pasos de aprobación paralelos, es decir, pasos aprobados al mismo tiempo (no secuencialmente). Si no tiene pasos de aprobación paralelos, ingrese 1. Siempre puede cambiar el número de pasos de aprobación más adelante.',
  APPROVAL_MUST_BE_AT_LEAST: 'La aprobación debe ser al menos',
  INSERT_THE_NEW_STATUS_NAME: 'Inserte el nuevo nombre de estado',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Inserte el nuevo nombre del flujo de trabajo.',
  INSERT_THE_NEW_STEP_NAME: 'Inserte el nuevo nombre del paso.',
  NEW_DOCUMENT: 'Nuevo documento',
  CREATE: 'Crear',
  DOCUMENT_MANAGEMENT: 'Gestión de documentos',
  CONFIRM: 'Confirmar',
  CONFIRM_PROCESS: 'CONFIRMAR PROCESO',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'DEFINIR PASOS DE APROBACIÓN Y EQUIPO',
  SET_DETAILS: 'ESTABLECER DETALLES',
  SELECT_TYPE: 'SELECCIONE TIPO',
  COMPLETE_LOCALIZATION_PROCESS: 'Proceso de localización completo',
  CREATE_NEW_VERSION: 'Crear nueva versión',
  CREATE_NEW_DOCUMENT: 'Crear nuevo documento',
  IS_PRIVATE: 'Es privada',
  SHARED_WITH_OTHER_TEAMS: 'Compartido con otros equipos',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Ingrese el número de pasos de aprobación secuenciales necesarios. Si dos aprobaciones están en paralelo, cuenta como un paso. Siempre puede cambiar el número de pasos de aprobación más adelante.',
  DONE: 'Hecho',
  SUBMIT_FOR_APPROVAL: 'Someter para aprobacion',
  YOU_HAVE_TO_CONFIRM_THE_: 'Debe confirmar el proceso antes de la carga.',
  SELECT_YOUR_DEC_TYPE: 'Seleccione su tipo de documento',
  TARGET_AUDIENCE: 'Público objetivo',
  PROCESS_DETAILS: 'Detalles del proceso',
  START_DATE: 'Fecha de inicio',
  DOC_DETAILS: 'Detalles del documento',
  CONFIRMATION: 'Confirmación',
  APPROVAL_PROCESS: 'Proceso de aprobación',
  SCHEDULED_EXP_DATE: 'Fecha de vencimiento programada',
  SCHEDULED_PUBLISHING_DATE: 'Fecha de publicación programada',
  NO_PROCESS_SELECTED: 'Ningún proceso seleccionado',
  COUNTRIES: 'Países',
  DOC_ID: 'ID del documento',
  THERAPEUTIC_AREA: 'Área Terapéutica',
  FILTER: 'Filtrar',
  BACK: 'espalda',
  ATTACH: 'Adjuntar',
  COPY_ANSWER: 'Copiar respuesta',
  SIMILAR_INQUIRIES: 'Consultas similares',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Plataformas de diapositivas',
  MEDICAL_DOC: 'Documento médico',
  SHOW: 'mostrar',
  LOAD_MORE: 'Carga más',
  NO_RESULT_FOUND: 'No se han encontrado resultados.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'se puede usar para buscar una oración completa, se puede usar para excluir de los resultados de búsqueda dentro de la palabra especificada',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Busque documentos médicos o consultas similares',
  SEARCH: 'BUSCAR',
  Preview: 'Avance',
  PREVIEW_AND_SEND: 'Previsualizar y enviar',
  PREVIEW_AS_PDF: 'Vista previa como pdf',
  PUBLISHED_VERSION: 'Versión publicada',
  NEW_VERSION: 'Nueva versión',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'El documento aún está en aprobación, si desea cambiar el contenido del documento, cargue otro archivo.',
  OUT_TO: 'fuera de',
  NEXT: 'siguiente',
  SELECTED_SLIDES: 'Diapositivas Seleccionadas',
  GO_TO_SLIDE: 'IR A DIAPOSITIVA',
  PREV: 'prev',
  SELECT_SLIDE: 'Seleccionar diapositiva',
  ANSWER: 'Responder',
  BACK_TO_STANDARD_VIEW: 'Volver a la vista estándar.',
  UPLOAD_NEW_FILE: 'Subir nuevo archivo',
  CREATE_FILE: 'CREA UN ARCHIVO',
  EDITING_WORD_DOC: 'Edición de documento de Word',
  INSERT_FILE_NAME: 'Insertar nombre de archivo',
  PROCEED_WITH_THE_LINK_: 'Continuar con el enlace del archivo',
  A_NEW_WINDOW_WILL_BE_:
    'Se abrirá una nueva ventana con un archivo vacío, escriba el contenido y cierre la pestaña',
  INSERT_THE_NAME_FOR_:
    'Inserte el nombre del archivo adjunto que desea crear y continúe con la creación del archivo',
  INSTRUCTIONS: 'Instrucciones',
  CREATE_NEW_WORD_FILE_FOR: 'Crear un nuevo archivo de palabras para',
  FROM: 'desde',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Puede encontrar aquí las últimas consultas que ha insertado en el sistema enviadas y aún no enviadas. Puede reutilizar las consultas que ha insertado para crear consultas más similares o volver a las consultas que no ha terminado de insertar.',
  MY_DRAFT_INQ: 'Mis borradores de consultas',
  WARNING_CHANGES_NOT_SAVED: 'Advertencia: cambios no guardados',
  PAGE_THAT_WILL_BE_LOST: 'página que se perderá si decide continuar.',
  YOU_HAVE_UNUSED: 'Tienes cambios no guardados en',
  ARE_YOU_SURE_YOU_WANT_TO_: '¿Seguro que quieres salir de esta página sin guardar?',
  LEAVE_THIS_PAGE: 'Salir de esta página',
  STAY_ON: 'Permanecer en',
  ASSIGNED_TO: 'Asignado a',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Esta consulta se ha insertado durante el evento.',
  STATUS_IN_APPROVAL: 'Estado: en aprobación',
  STATUS_DRAFT: 'Estado: borrador',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Si desea crear la consulta real, vaya a la pestaña Enviar y haga clic en el botón Crear.',
  INQ_IMPORTED_FROM: 'Consulta importada de',
  SEND_NEW_ANSWER: 'ENVIAR NUEVA RESPUESTA',
  RETURN_TO_INBOX: 'REGRESAR A INBOX',
  SUBMITTED_FOR_APPROVAL: 'Presentado para su aprobacion',
  ANSWER_SUBMITTED_ON: 'Respuesta presentada el',
  BY: 'por',
  INQ_HAS_BEEN_CLOSED: 'La consulta se ha cerrado sin respuesta.',
  BCC: 'Bcc',
  ANSWER_GIVEN_ON: 'Respuesta dada el',
  ANSWER_SENT_ON: 'Respuesta enviada el',
  INBOX: 'Bandeja de entrada',
  OPEN_ORIGINAL_INQ: 'Abrir consulta original',
  CLOSE_ANSWER_NOT_NEEDED: 'Cerrar: no se necesita Anser',
  HISTORY: 'Historia',
  ADD_A_NOTE: 'Agrega una nota',
  ADD_A_NOTE_INTO_: 'Agregue una nota en el historial de consultas.',
  SHARE_OR_START_A_FOLLOW_UP: 'Comparte o inicia un seguimiento',
  SEE_ALL_INQ_RELATED_TO: 'Ver todas las consultas relacionadas con',
  SRD_NEEDED: 'SRD necesario',
  ANSWER_UNAVAILABLE: 'Respuesta no disponible',
  QUESTION: 'Pregunta',
  SUBMIT: 'ENVIAR',
  ANSWER_UPPERCASE: 'RESPONDER',
  DETAILS: 'DETALLES',
  SPLIT_INQ: 'Consulta dividida',
  ADD_QUESTION: 'Añadir pregunta',
  ENTER_QUESTION: 'Ingrese la pregunta',
  MARK_AS_ANSWERED: 'Marcar como respondida',
  TO_BE_ANSWERED: 'Ser respondida',
  FOLLOW_UP_RECEIVED: 'Seguimiento recibido',
  ERROR: 'Error',
  SHOW_ORIGINAL_MESSAGE: 'Mostrar mensaje original',
  SEND: 'Enviar',
  DO_NO_SEND: 'No envíe',
  OK: 'Okay',
  MAX_LENGTH_FOR_ZIP_CODE: 'La longitud máxima para el código postal es',
  CHARACTERS: 'caracteres',
  ADDRESS_1_IS_: 'La dirección 1 es',
  LABEL_IS: 'Etiqueta es',
  NO_QUESTION_INSERTED: 'Ninguna pregunta insertada',
  WARNING: 'ADVERTENCIA',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'El seguimiento no está disponible para esta interacción.',
  INSERT_INQ_TEXT: 'Insertar texto de consulta',
  SHOW_MORE: 'Mostrar más',
  OUT_OF: 'fuera de',
  INQ_TO_BE_SENT: 'Consultas para ser enviadas',
  HIDE_INQ: 'Ocultar consultas',
  SHARE: 'Compartir',
  HCP_PROFILE_OF: 'PERFIL HCP de',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Complete todos los campos obligatorios a continuación y al menos una dirección de correo electrónico y luego guárdelo para obtener una vista previa de la notificación de recibo de GDPR.',
  CONFIRMATION_SENT_BY: 'Notificación enviada por',
  COUNTRY_IS: 'El país es',
  COUNTRY: 'País',
  AT_LEAST_ONE_CONTACT_IS_: 'Al menos un contacto es',
  EMAIL_IS: 'El correo electrónico es',
  EMAIL_LABEL_IS: 'La etiqueta del correo electrónico es',
  PHONE_FAX_IS: 'La etiqueta del teléfono / fax es',
  PHONE_FAX_NUMBER_IS: 'El número de teléfono / fax es',
  ADDRESS: 'Habla a',
  DELETE_PERSONAL_DATA: 'Eliminar datos personales',
  HCP_VERIFIED: 'HCP verificada',
  KEY_OPINION_LEADER: 'Lider de opinion clave',
  HOSPITAL_BASED: 'Basado en el hospital',
  HCP_RELATED_INFORMATION: 'INFORMACIÓN RELACIONADA CON HCP',
  ACCEPT: 'Aceptar',
  REJECT: 'Rechazar',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: '¿Quieres aceptar estas sugerencias?',
  SALES_REP: 'REPRESENTANTE DE VENTAS',
  SUGGEST_A_TOPIC: 'Sugerir un tema',
  TEAM_SPECIFIC_FIELDS: 'CAMPOS ESPECÍFICOS DE EQUIPO',
  ADR_RELATED: 'Relacionada con ADR',
  NOT_ADR_RELATED: 'No relacionada con ADR',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Revise el mensaje y clasifíquelo como relacionado con Reacción adversa al medicamento o no.',
  ADVERSE_DRUG_REACTION: 'Reacción adversa al medicamento',
  CHANGE_DETAILS: 'CAMBIAR DETALLES',
  YES: 'si',
  NO: 'No',
  DUE_DATE: 'Fecha de vencimiento',
  CRITICAL: 'Crítica',
  NORMAL: 'Normal',
  PRIORITY: 'Prioridad',
  SELECT_DETAILS: 'SELECCIONE DETALLES',
  SAVE_REVIEW: 'Guardar reseña',
  SET_TO_ALL_INQ: 'ESTABLECER A TODAS LAS CONSULTAS',
  PRODUCT_QUALITY_COMPLAINT: 'Queja de calidad del producto',
  PQC_PC_A_PRODUCT_:
    'PQC / PC Una queja o problema de calidad del producto (QC) se define como cualquier preocupación que implique un defecto o mal funcionamiento de cualquier producto farmacéutico o con respecto a la calidad, el rendimiento y la seguridad de cualquier producto farmacéutico, p. olor y / o sabor alterados, embalaje deficiente o defectuoso, sospecha de contaminación, etc.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Uso no indicado en la etiqueta de medicamentos farmacéuticos para una indicación no aprobada o en un grupo de edad, dosis o vía de administración no aprobada.',
  A_RESPONSE_TO_A_MEDICINAL_:
    'Una respuesta a un medicamento que es nociva y no intencionada y la relación causal entre un medicamento y un evento adverso es al menos una posibilidad razonable. También los casos de situaciones especiales (por ejemplo, casos de embarazo, uso no indicado en la etiqueta, sobredosis) deben marcarse como ADR.',
  NORMAL_IS_7_WORKING_DAYS:
    'Normal es de 7 días hábiles, alta es de 48 horas y crítica (relacionada con el paciente) es de 24 horas',
  CATEGORY_AND_TOPIC_ARE: 'Categoría y tema son',
  PRODUCT_IS: 'Producto es',
  DO_YOU_WANT_TO_ACCEPT_: '¿Quieres aceptar estas sugerencias?',
  MERGE: 'Go',
  Duplicate: 'Duplicada',
  Other: 'Otra',
  Spam: 'Correo no deseado',
  CLOSE_INTERACTION: 'Interacción cercana',
  RE_OPEN_THE_TARGET_: 'Vuelva a abrir la consulta de destino después de la fusión.',
  MARK_AS_INTERNAL_: 'Marcar como mensaje interno para la consulta existente.',
  MARK_AS_AN_ERROR_: 'Marcar como mensaje de error para la consulta existente.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Este mensaje se marcará como respuesta de seguimiento de una consulta existente.',
  THANK_YOU: 'Gracias',
  AN_INTERNAL_MESSAGE: 'Un mensaje interno',
  AN_ERROR_MESSAGE: 'Un mensaje de error',
  A_FOLLOW_UP_RESPONSE: 'Una respuesta de seguimiento',
  THIS_INQ_IS: 'Esta consulta es',
  SPLIT_INTERACTION: 'Interacción dividida',
  SAVE_AND_CLOSE: 'Guardar y cerrar',
  SAVE_INQ: 'Guardar consulta',
  REVIEW_THE_TEXT_AND: 'Revise el texto y la categorización, y confirme',
  CHOOSE_THE_RELEVANT_PRODUCT:
    'Elija el producto, la categoría y el tema relevantes para el texto seleccionado.',
  SELECT_THE_TEXT_THAT_:
    'Seleccione el texto que identifica la pregunta sobre un producto / tema (no incluya saludos, firmas, exenciones de responsabilidad u otra información no relevante)',
  REPEAT_THE_PREVIOUS_: 'Repita los pasos anteriores para agregar más consultas.',
  READ_MORE: 'Lee mas',
  READ_LESS: 'Leer menos',
  DO_NOT_INCLUDE_:
    'no incluya saludos, firmas, exenciones de responsabilidad u otra información no relevante',
  SELECT_THE_TEXT_THAT_IDENT:
    'Seleccione el texto que identifica la pregunta sobre un producto / tema',
  IF_YOU_HAVE_MULTIPLE_: 'Si tiene varios productos o temas, la consulta debe dividirse.',
  THE_FOLLOWING_PROCESS_:
    'El siguiente proceso lo ayudará a identificar el texto relevante para cada producto / tema: ',
  EXPORT_TO_PDF: 'EXPORTAR A PDF',
  Added: 'Agregada',
  Deleted: 'Eliminada',
  From: 'Desde',
  To: 'A',
  BACK_TO_INBOX: 'De regreso a la bandeja de entrada',
  PERMISSION_DENIED: 'Permiso denegado',
  THIS_INQ_IS_LOCKED_BY: 'Esta consulta está bloqueada por',
  FORCE_UNLOCK: 'DESBLOQUEO DE FUERZA',
  INQUIRIES: 'Consultas',
  Download: 'Descargar',
  Event: 'Evento',
  INSERTED_BY: 'Insertado por',
  LAST_EDITED_ON: 'Última edición en',
  LAST_EDITED_BY: 'Última edición por',
  CREATED_ON: 'Creado en',
  TOPIC: 'Tema',
  TABLE_OPTIONS: 'Opciones de mesa',
  DEPARTMENT: 'Departamento',
  User: 'Usuaria',
  ADD_THERAPEUTIC_AREA: 'Agregar área terapéutica',
  CREATE_THERAPEUTIC_AREA: 'Crear nueva área terapéutica',
  ADD_NEW: 'Agregar nueva',
  SELECT_TEAM_TO_PROCEED: 'Seleccionar equipo para continuar',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Ventas',
  CALL_CENTER: 'Centro de llamadas',
  ALL: 'Todas',
  Warning: 'Advertencia',
  clear: 'clara',
  THESE_CHANGES_WILL_BE_: 'Estos cambios se aplicarán a los usuarios seleccionados.',
  INSERT_A_FOLLOW_UP_RESP: 'Insertar una respuesta de seguimiento',
  INSERT_A_NOTE: 'Insertar una nota',
  MERGED_FROM: 'Fusionado de',
  CHANGES: 'Cambios',
  SHOW_LESS: 'Muestra menos',
  COMPLETE: 'Completa',
  MISSING: 'Desaparecida',
  CLOSE_AS_FORWARDED: 'Cerrar como reenviado',
  CLOSE_EXT_TEAM: 'Cierre la consulta como se reenvía al equipo externo.',
  THE_INQ_BELONG_TO_A_:
    'Esta consulta pertenece a un país que es responsabilidad de un equipo externo. La consulta se enviará automáticamente a este equipo.',
  RESOLVE: 'Resolver',
  TERRITORIES: 'Territorios',
  EXTERNAL_COMMUNICATION: 'COMUNICACION EXTERNA',
  FOR_INQ_COMING_DIRECTLY_:
    'Para consultas que lleguen directamente del HCP por correo electrónico, envíe automáticamente una notificación para informarle que se ha recibido la consulta.',
  INTERNAL_COMMUNICATION: 'COMUNICACIÓN INTERNA',
  SALESREP_RECEIVES_: 'Salesrep solo recibe notificaciones de que se respondió la consulta',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep recibe respuesta completa para consultas On Label',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'Salesrep solo recibe notificaciones de que se respondió la consulta',
  Name: 'Nombre',
  Module: 'Módulo',
  Function: 'Función',
  LAST_RUN: 'Última carrera',
  End: 'Fin',
  Every: 'Cada',
  Period: 'Período',
  Active: 'Activa',
  Resolved: 'Resuelta',
  ERROR_PREVIEW: 'Vista previa de error',
  FAILURE_COUNT: 'Recuento de fallas',
  COMPLETED_ON: 'Completado en',
  STARTED_ON: 'Comenzó en',
  TASK_NAME: 'Nombre de la tarea',
  PRODUCT_SPECIFIC: 'Producto específico',
  THERAPEUTIC_AREAS: 'ÁREAS TERAPÉUTICAS',
  PRODUCT_GROUPS: 'GRUPOS DE PRODUCTOS',
  LICENSING_PARTNER: 'SOCIO DE LICENCIA',
  PRODUCT_CODE: 'CÓDIGO DE PRODUCTO',
  EXTERNAL_ID: 'ID externo',
  OBJECT_TYPE: 'Tipo de objeto',
  MIGRATION_NAME: 'Nombre de migración',
  MIGRATION_ERRORS: 'Errores de migración',
  ADD_SALES_REP: 'Agregar representante de ventas',
  ASSIGNED_TO_MSL: 'ASIGNADO A MSL',
  NOT_ASSIGNED: 'No asignado',
  ASSIGNED_TO_OTHER_MSL: 'Asignado a otro MSL',
  ASSIGNED_TO_THIS_MSL: 'Asignado a este MSL',
  SHOW_ONLY: 'Mostrar solo',
  SALES_REP_LIST: 'Lista de representantes de ventas',
  ACTIVE_TASK: 'Activar tarea',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Elija usuario y configure el turno para el evento',
  MANAGE_SHIFTS: 'GESTIONAR CAMBIOS',
  CREATE_NEW_USER: 'Crear nueva usuaria',
  THIS_TEMPLATE_WILL_BE_AUTO_:
    'Esta plantilla se agregará automáticamente a su miembro del equipo.',
  TEMPLATE_FOR: 'Plantilla para',
  Day: 'Día',
  Week: 'Semana',
  Month: 'Mes',
  Today: 'Hoy',
  Archive: 'Archivo',
  PARTICIPANTS: 'PARTICIPANTES',
  EVENT_MANAGEMENT: 'GESTIÓN DE EVENTOS',
  ADD_TASK: 'Agregar tarea',
  EDIT_TASK: 'Editar tarea',
  IS_ACTIVE: 'Está activo',
  Months: 'Meses',
  Days: 'Dias',
  Hours: 'Horas',
  Minutes: 'Minutos',
  HCP_ADMINISTRATION: 'ADMINISTRACION HCP',
  AUTH_REQ_ADMINISTRATION: 'Administración de solicitudes de autenticación',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'El producto se resaltará en las áreas terapéuticas seleccionadas.',
  PRODUCT_GROUPS_DEFINE_:
    'Los grupos de productos definen el equipo responsable de responder las consultas de este producto.',
  USER_ADMINISTRATION: 'Administración de usuarios',
  IS_PRODUCT_SPECIFIC: 'Es específico del producto',
  USER_TEMPLATES_ADMIN: 'Administración de plantillas de usuario',
  SET_DEFAULT_VALUES: 'ESTABLECER VALORES POR DEFECTO',
  SET_DEFAULT_VALUES_FOR_: 'Establecer valores predeterminados para el evento',
  INQUIRY_DEFAULT_VAL: 'Consulta valores predeterminados',
  CREATE_NEW_EVENT: 'CREAR NUEVO EVENTO',
  EDIT_EVENT: 'EDITAR EVENTO',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Establecer detalles para crear un nuevo evento',
  ADD_DOC_LINKED_TO_: 'Agregar documentos vinculados al evento',
  INC_CHANNEL: 'Canal Inc.',
  CHANGE_DETAILS_OF_THE_EVENT: 'Cambiar detalles del evento',
  DESCRIPTION: 'Descripción',
  CREATE_APPROVAL_PROCESS: 'Elija proceso de aprobación',
  CREATE_NEW: 'Crear nueva',
  CUSTOMIZE_PROCESS: 'Proceso personalizado',
  SELECTED_PROCESS: 'Proceso seleccionado',
  CLONE: 'Clon',
  PREFERENCES_TITLE: 'Preferencias',
  NOTIFICATIONS_TITLE: 'Notificaciones',
  TEMPLATES: 'Plantillas',
  LETTER_TEMPLATES: 'Plantillas de carta',
  USER_TEMPLATES: 'Plantillas de usuario',
  TEAM_MEMBERS: 'Miembros del equipo',
  NEW_PASSWORD: 'Nueva contraseña',
  REPEAT_NEW_PASSWORD: 'repita la nueva contraseña',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Al menos 1 letra minúscula',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Al menos 1 letra mayúscula',
  AT_LEAST_1_DIGIT: 'Al menos 1 dígito',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Al menos 1 personaje especial',
  MINIMUM_8_CHARACTERS: 'Mínimo 8 caracteres',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'No debe contener correo electrónico o nombres',
  SHOULD_MATCH_PASSWORD: 'Debe coincidir con la contraseña',
  USER_MANUAL: 'Manual de usuario',
  SUPPORT: 'Apoyo',
  BACK_TO_LOGIN: 'Atrás para iniciar sesión',
  PASSWORD_CONFIRMATION: 'Confirmación de contraseña',
  HAVE_AN_ACCOUNT: '¿Ya tienes una cuenta?',
  Submit: 'Enviar',
  Gender: 'Género',
  PROFESSION: 'Profesión',
  MEDICAL_INQ_ROLES: 'Rol de procesamiento de consultas médicas',
  MEDICAL_INFO_MANAGER: 'Gerente de información médica',
  MEDICAL_SCIENCE_LIAISON: 'Medical Science Liaison',
  NOT_PROCESSING_INQUIRIES: 'No procesar consultas',
  ACTIVE_HEALTH_CARE_SPEC: 'Especialista en atención médica activa',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Especialista en atención médica no activa',
  PASS_IS_REQUIRED: '¡Se requiere contraseña!',
  EMAIL_IS_REQUIRED: '¡Correo electronico es requerido!',
  VALID_EMAIL: '¡Por favor, introduce una dirección de correo electrónico válida!',
  FIELD_IS_REQUIRED: '¡Este campo es requerido!',
  ONLY_LETTERS: 'Por favor ingrese solo letras!',
  ARE_YOU_daiichi_EMPLOYEE: '¿Eres empleada de Daiichi Sankyo?',
  here: 'aquí',
  CREATE_AN_ACCOUNT: 'Crea una cuenta',
  FORGOT: '¿Olvidó?',
  Medical: 'Médica',
  Medical_not_resp: 'Médico: no es responsable de responder a MIR',
  Commercial: 'Comercial',
  Market_access: 'Acceso al mercado',
  Pharmacovigilance: 'Farmacovigilancia',
  PR: 'PR',
  Please_specify: 'Por favor especifica',
  Medical_information_manager: 'Gerente de información médica',
  Medical_advisor: 'Asesora medica',
  Are_Medical_inquiries: '¿Eres responsable de responder consultas médicas?',
  Are_Health_care_professional: '¿Es usted un profesional de la salud en ejercicio?',
  Health_care_Industry: 'Industria del cuidado de la salud',
  Health_Authority: 'Autoridad sanitaria',
  Journalist: 'Periodista',
  Lawyer: 'Abogada',
  Patient: 'Paciente',
  Payor: 'Pagadora',
  Scientist: 'Científica',
  Angiologist: 'Angiólogo',
  Cardiologist: 'Cardióloga',
  Cardiovascular_Surgeon: 'Cirujano cardiovascular',
  Dentist: 'Dentista',
  Gastroenterologist: 'Gastroenteróloga',
  General_Practitioner: 'Médico general',
  Geriatrician: 'Geriatra',
  Gynecologist: 'Ginecóloga',
  Haematologist: 'Hematóloga',
  Internist: 'Internista',
  Medical_Student: 'Estudiante de medicina',
  Nephrologist: 'Nefróloga',
  Neurologist: 'Neuróloga',
  Neurosurgeon: 'Neurocirujana',
  Nurse: 'Enfermera',
  Oncologist: 'Oncóloga',
  Oncology_Nurse: 'Enfermera de oncología',
  Other_HCP: 'Otros profesionales sanitarios',
  Other_Surgeon: 'Otro cirujano',
  Pediatrician: 'Pediatra',
  Pharmacist: 'Farmacéutica',
  Pharmacologist: 'Farmacóloga',
  Pharmacy_Technician: 'Tecnica de farmacia',
  Pneumologist: 'Neumóloga',
  Radiologist: 'Radióloga',
  Rheumatologist: 'Reumatóloga',
  USER_PASS_INCORRECT: 'Nombre de usuario o contraseña incorrecta',
  SUBMITTED_REQUEST: 'Su solicitud se envió correctamente.',
  Reset: 'Reiniciar',
  Reset_pass: 'Restablecer la contraseña',
  New_pass: 'Nueva contraseña',
  RESET_PASS_EMAIL: 'Se envió un enlace de restablecimiento de contraseña a su correo electrónico',
  NEW_PASS_SUCCESS: 'Su contraseña ha sido restablecida exitosamente',
  SOMETHING_WENT_WRONG: 'Algo salió mal, por favor contacte al soporte.',
  EVENTS: 'Eventos',
  Login_here: 'Entre aquí',
  REGISTRATION: 'Registro',
  Create_an_account: 'Crea una cuenta',
  NO_RECORDS: 'No hay registros',
  INTERACTION: 'Interacción',
  SPECIALIZATION: 'Especialización',
  SPECIALIZATION_TYPE: 'Tipo de especialización',
  SAVED: 'Salvado',
  SAVING: 'Ahorro',
  TYPING: 'Mecanografía',
  CREATE_NEW_INTERACTION: 'Crear nueva interacción',
  FILTER_BY: 'Filtrado por',
  SEND_EMAIL: 'Enviar correo electrónico',
  SAVE_WITHOUT_EMAIL: 'Ahorre sin correo electrónico',
  SUBMIT_TO_INBOX: 'Enviar a la bandeja de entrada',
  Create_my_acc: 'Crear mi cuenta',
  NEW_QUESTION: 'Nueva pregunta',
  LABEL: 'Etiqueta',
  SELECT_A_LABEL: 'Selecciona una etiqueta',
  CITY: 'Ciudad',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Sugerir una nueva categoría o seleccionar una existente',
  SUGGEST_CATEGORY_OR_TOPIC: 'Sugerir una nueva categoría o tema',
  URGENT: 'Urgente',
  EXISTING_CATEGORY: 'Categoría existente',
  NEW_CATEGORY_NAME: 'Nuevo nombre de categoría',
  NEW_TOPIC_NAME: 'Nuevo nombre del tema',
  CATEGORY: 'Categoría',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Canal saliente',
  LOGIN_TO_CONTINUE: 'Inicie sesión para continuar a Madjenta',
  Email_address: 'Dirección de correo electrónico',
  FORGOT_PASS: '¿Se te olvidó tu contraseña?',
  Remember_me: 'Recuérdame',
  YOU_WILL_BE_NOTIFIED:
    'Se envió un correo electrónico de confirmación con más instrucciones para su registro.',
  SUCCESS_EMAIL_CONFIRMATION: 'Su correo electrónico fue confirmado con éxito.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Se le notificará cuando su solicitud haya sido revisada',
  FAILED_EMAIL_CONFIRMATION: 'No podemos confirmar su correo electrónico.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Vuelva a intentarlo y, si el problema persiste, escriba al servicio de asistencia con la descripción del problema.',
  RESET_FILTERS: 'Restablecer filtros',
  NEW_PASS_RESET_SUCCESS: '¡Restablecimiento de contraseña exitoso!',
  Cardiovascular: 'Cardiovascular',
  Oncology: 'Oncología',
  Due_On: 'Debido a',
  Follow_Up_Sent_On: 'Seguimiento enviado el',
  Follow_Up_Received_On: 'Seguimiento recibido el',
  Closed_On: 'Cerrado el',
  Reopened_On: 'Reabierto en',
  Inquiry_ID: 'ID de consulta',
  Search: 'Buscar',
  Assigned_to_Now: 'Asignado a (ahora)',
  Edited_By: 'Editado por',
  Assigned_to_Any_Time: 'Asignado a (En cualquier momento)',
  Closed: 'Cerrada',
  Foreign: 'Extranjera',
  Complex_search_Sentence_: 'Búsqueda compleja: Oración: "oración para buscar", No: -word',
  Search_inquiries: 'Consultas de búsqueda',
  SEARCH_REPOSITORY: 'Buscar repositorio',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'Filtros',
  Active_Filters: 'Filtros activos',
  CONTACT_SUPPORT: 'Soporte de contacto',
  MedicalInformationRequests: 'Consultas (pdf)',
  UserProfileNavigation: 'Perfil de usuario (pdf)',
  UserManualChanges: 'Cambios en el manual del usuario (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Asignar consulta',
  MedIS_Documentation_Approval_Process: 'Proceso de aprobación de documentación',
  MedIS_Documentation_Overview: 'Resumen de documentación',
  MedIS_Inquiry_Documentation: 'Documentación de consulta',
  MedIS_MIR_Inbox_Filters: 'Filtros de bandeja de entrada MIR',
  MedIS_Merge_MIR: 'Fusionar MIR',
  MedIS_New_Documentation: 'Nueva documentación',
  MedIS_New_Inquiry: 'Nueva investigación',
  MedIS_Out_Of_Office: 'Fuera de la oficina',
  MedIS_Prepare_Custom_Answer: 'Preparar respuesta personalizada',
  MedIS_Split_MIR_Part_I: 'Split MIR Parte I',
  MedIS_Split_MIR_Part_II: 'Split MIR Parte II',
  I_AGREE_TO: 'Estoy de acuerdo',
  TermsAndConditionsLink: 'Términos y Condiciones',
  Title: 'Título',
  INTERACTION_TITLE: 'Título de interacción',
  Move: 'Moverse',
  Left: 'Izquierda',
  Right: 'Derecha',
  Requestor: 'Solicitante',
  Assigned_To: 'Asignado a',
  Status: 'Estado',
  Note: 'Nota',
  Confirm: 'Confirmar',
  rejectConfirm: '¿Seguro que quieres rechazar la solicitud?',
  approveConfirm: '¿Estás segura de que quieres aprobar la solicitud?',
  newUser: 'Nueva usuaria',
  editUser: 'editar usuario',
  deleteUser: 'Borrar usuario',
  newProduct: 'Nuevo producto',
  editProduct: 'Editar producto',
  deleteProduct: 'Eliminar producto',
  newCategory: 'Nueva categoría',
  editCategory: 'Editar categoria',
  deleteCategory: 'Eliminar categoría',
  newTopic: 'Nuevo tema',
  editTopic: 'Editar tema',
  deleteTopic: 'Eliminar tema',
  userRequest: 'Solicitud del usuario',
  productRequest: 'Solicitud de producto',
  categoryRequest: 'Solicitud de categoría',
  categoryTopicRequest: 'Solicitud de categoría / tema',
  topicRequest: 'Solicitud de tema',
  Suggestion: 'Sugerencia',
  tradeName: 'Nombre comercial',
  activeSubstance: 'Substancia activa',
  productName: 'nombre del producto',
  productSpecific: '¿Es específico del producto?',
  EMAIL_IN_USE: 'Hay una cuenta existente con este correo electrónico',
  fromDate: 'Partir de la fecha',
  toDate: 'Hasta la fecha',
  applyFilter: 'Aplicar filtro',
  requestReceivedFrom: 'Solicitud recibida de',
  on: 'en',
  at: 'a',
  to: 'a',
  add: 'añadir',
  from: 'de',
  approve: 'aprobar',
  reject: 'rechazar',
  withTheFollowingRole: 'con el siguiente rol',
  forTheFollowingTeam: 'para el siguiente equipo',
  request: 'solicitud',
  submittedBy: 'presentado por',
  INVALID_OR_EXPIRED_RESET_REQUEST:
    'La solicitud de restablecimiento de contraseña no es válida o ha caducado',
  PASS_IS_RECENT: 'La contraseña se usó recientemente',
  Assigned_to_me: 'Asignado a mí',
  My_drafts: 'Mis borradores',
  My_open_inquiries: 'Mis consultas abiertas',
  My_closed_inquiries: 'Mis consultas cerradas',
  Filter_inquiries_by: 'Filtrar consultas por',
  Inq_inbox: 'Bandeja de entrada de consultas',
  REPORTS: 'Informes',
  My_teams_open_inq: 'Las consultas abiertas de mi equipo',
  My_teams_closed_inq: 'Las consultas cerradas de mi equipo',
  All_teams_open_inq: 'Todos los equipos abren consultas',
  All_teams_closed_inq: 'Todos los equipos cerraron consultas',
  Pending_approval: 'Aprobación pendiente',
  ADMINISTRATION: 'Administración',
  Show_all: 'Mostrar todo',
  In_progress: 'En progreso',
  Table_Settings_Columns: 'Configuración de tabla - Columnas',
  Change_column_visibility: 'Cambiar visibilidad de columna',
  Reassign_to: 'Reasignar a',
  set_priority: 'Fijar prioridad',
  set_status: 'Establecer estado',
  Spam_list: 'Lista de spam',
  Institution: 'Institución',
  DUE_ON: 'Debido a',
  Profile_settings: 'Configuración de perfil',
  Preferences: 'Preferencias',
  Role_settings: 'Configuraciones de roles',
  Notification_settings: 'Configuración de las notificaciones',
  User_profile: 'Perfil del usuario',
  Select_person: 'Seleccionar persona',
  FROM_DATE: 'Partir de la fecha',
  TO_DATE: 'Hasta la fecha',
  JOB_DESC: 'Descripción del trabajo',
  INQ_Management: 'Gestión de consultas',
  DOCS_Management: 'Gestión de documentos',
  USER_Management: 'Gestión de usuarios',
  Define_Frequency: 'Definir frecuencia',
  All_teams_all_inq: 'Todas las consultas para todos los equipos.',
  My_teams_all_inq: 'Todas las consultas para mi equipo',
  Search_people_here: 'Busca personas aquí',
  Search_team_here: 'Busca equipo aquí',
  people: 'PERSONAS',
  teams: 'EQUIPOS',
  empty: '(vacía)',
  can_not_be_assigned: 'Esta usuario no tiene equipo y no puede ser asignada',
  select_a_team: 'Por favor seleccione un equipo para',
  confirm_team_change: 'Confirmar cambio de equipo',
  change_the_team_: '¿Estás seguro de que quieres cambiar el equipo a',
  can_not_see_it_anymore: 'No podrás verla en la corriente.',
  filtered_view: 'vista filtrada',
  PHONE: 'Teléfono',
  FAX: 'Fax',
  LETTER: 'Letra',
  F2F_SITE_VISIT: 'F2F (visita al sitio)',
  F2F_CONGRESS: 'F2F (Congreso)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (otro)',
  WEBINAR_SITE_VISIT: 'Virtual (visita al sitio)',
  WEBINAR_CONGRESS: 'Virtual (Congreso)',
  WEBINAR_ADBOARD: 'Virtual (AdBoard)',
  WEBINAR_OTHER: 'Virtual (otra)',
  CHAT: 'Charla',
  COURIER: 'Mensajera',
  REP_DELIVERY: 'Rep. Entrega',
  SYSTEM: 'Sistema',
  WEB_FORM: 'Formulario web',
  Signature: 'Firma',
  Disclaimer: 'Descargo de responsabilidad',
  STATE: 'Estado',
  ADDRESS_1: 'Dirección 1',
  ADDRESS_2: 'Dirección 2',
  ZIP_CODE: 'Código postal',
  HIGH: 'Alta',
  ADVANCED: 'Avanzada',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Haga clic en el texto y los campos a continuación para completar y editar la respuesta',
  DOCUMENT_VIEW: 'Vista de documento',
  FORM_VIEW: 'Vista de formulario',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'No tiene derechos para enviar la respuesta.',
  ANSWER_TEXT: 'Texto de respuesta',
  CONTACT_INFORMATION: 'Información del contacto',
  COMPANY: 'Empresa',
  SRD_NEEDED_TOOLTIP: 'La respuesta a esta consulta requiere un nuevo documento SRD',
  ENABLE_ALL: 'Activar todo',
  DISABLE_ALL: 'Desactivar todo',
  BEFORE: 'antes de',
  AFTER: 'Después',
  EDIT: 'Editar',
  MY_TEMPLATES: 'Mis plantillas',
  TEAM_TEMPLATES: 'Plantillas de equipo',
  TEAM_TEMPLATES_HINT: 'Plantilla creada por Medical Information Manager para todo el equipo',
  SOURCE: 'Fuente',
  TEMPLATE_NO_MATCH_INTERACTION:
    'Esta plantilla no coincide con los detalles de la interacción y no es aplicable',
  EMAIL_SUBJECT: 'Asunto del email',
  INQUIRY_SUBMIT_HINT:
    'Por favor, complete todos los campos obligatorios para responder a la consulta.',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Publicación programada hasta la fecha de vencimiento',
  COPY_ATTACHMENT_TO_ANSWER: 'Copiar adjunto para responder',
  COPY_TEXT_TO_ANSWER: 'Copiar texto para responder',
  SELECT_INQUIRY: 'Seleccionar consulta',
  EDIT_INQ: 'Editar pregunta',
  SPLIT_ANOTHER: 'Dividir otro',
  DELETE_INQ: 'Eliminar consulta',
  SELECT_TEXT_FOR_SPLIT: 'Agregar una nueva pregunta en la misma interacción',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Generar interacción / caso por separado',
  SPLIT_ORIGINAL_TEXT: 'Texto original (seleccionar texto para dividir)',
  SPLIT_TEXT_TO_REMAIN_: 'Texto para permanecer en consulta inicial',
  SPLIT_TEXT_TO_SPLIT_: 'Texto a dividir',
  SPLIT_SELECTED_SECTIONS: 'Secciones seleccionadas para dividir',
  SPLIT_NO_MODIFICATIONS_HINT:
    'El texto se completará automáticamente a medida que seleccione secciones del texto original.',
  SPLIT_MODIFICATIONS_HINT:
    'El texto se ha modificado manualmente y no se aplicará ninguna otra selección del texto original.\n Haga clic aquí para descartar las modificaciones.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'El texto se actualizará automáticamente con los cambios en los detalles de la consulta.',
  SELECT_DIFFERENT_TEMPLATE: 'Seleccione una plantilla diferente',
  APPROVE: 'Aprobar',
  LAST_EDIT_ON: 'Última edición en',
  LAST_EDIT_BY: 'Última edición por',
  INTERNAL_COMM: 'Comunicación interna',
  INACTIVE: 'Inactiva',
  DEACTIVATE: 'Desactivar',
  ARCHIVED: 'Archivada',
  REPUBLISH: 'Republicar',
  INBOX_NO_FILTERS_HINT:
    'No hay filtros seleccionados.\n Si no puede encontrar la consulta que está buscando, compruebe que se encuentra en la sección de bandeja de entrada correcta.',
  INTERACTION_TEMPLATE_HINT:
    'Las firmas, los saludos y las renuncias son parte de la comunicación escrita, como correo electrónico, carta y fax.\n Para usarlos, asegúrese de que el canal de salida esté configurado en uno de ellos.',
  CONFIRM_AUTH_REQUEST: 'Confirmar solicitud de autenticación',
  VIEW_AUTH_REQUEST: 'Ver solicitud de autenticación',
  QUESTION_TITLE: 'Título de la pregunta: ',
  NO_TITLE_INQUIRY: '-no-titulo-consulta-',
  EXISTING_CATEGORIES: 'Categorías existentes',
  EXISTING_TOPICS: 'Temas existentes',
  DO_NOT_ASSIGN_PRODUCT: 'No asignar producto',
  QUESTION_TEXT: 'Texto de la pregunta',
  DATE: 'Fecha',
  REJECTED: 'Rechazada',
  FILTER_AUTH_REQUESTS_BY: 'Filtrar solicitudes de autenticación por',
  ALL_REQUESTS_ALL_TEAMS: 'Todas las solicitudes para todos los equipos',
  ALL_TEAMS: 'Todos los equipos',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'No tiene derechos para editar solicitudes de categorías / temas.',
  SEND_FOLLOW_UP: 'Enviar seguimiento',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Notificación de recibo a la clienta',
  GDPR_DISCLAIMER: 'Descargo de responsabilidad del RGPD',
  ACTIONS: 'Comportamiento',
  SUBMISSION_CONDITIONS: 'Condiciones de envío',
  TYPE_OF_NOTIFICATION: 'Tipo de notificación',
  DIRECT_SUBMISSION: 'Envío directo',
  DIRECT_SUBMISSION_TOOLTIP:
    'Para consultas enviadas directamente por el cliente por correo electrónico, envíe una notificación para informar al cliente sobre el manejo de sus datos personales.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Enviado a través de un empleado de la empresa.',
  ENABLE_NOTIFICATION: 'Deshabilitar la notificación automática',
  NOTIFICATION_SENDER: 'Notificación al remitente',
  SALES_REP_DELIVERED_RESPONSE: 'Representante de ventas para respuesta entregada',
  NOTIFICATION_DELIVERED_RESPONSE: 'Notificación de respuesta entregada',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'Para consultas enviadas a través de un representante de la empresa (representante de ventas, MSL u otro), notifique al remitente cuando el cliente haya recibido una respuesta a su consulta.',
  FULL_ANSWER: 'Respuesta completa',
  NOTIFICATION: 'Notificación',
  NAME: 'Nombre',
  COLUMN_INIT_QUESTION_TEXT: 'Texto original de la pregunta',
  NOTIFICATION_ENABLED: 'Notificación habilitada',
  DEFAULT_TEMPLATE: 'Plantilla predeterminada',
  SET_AS_DEFAULT: 'Set as default',
  DEFAULT_TEMPLATE_TOOLTIP:
    '¡Alternar está deshabilitado ya que debe haber al menos una plantilla predeterminada!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE:
    '¡La plantilla predeterminada no se puede eliminar!',
  BATCH_UPDATE_ONLY:
    'Solo es posible una actualización por lotes a través de la casilla de verificación superior.',
  ACCESS_DENIED: '¡Acceso denegado!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'No tienes permiso para acceder a esta página.',
  USER_ROLES: 'Roles del usuario',
  MANAGE_USER_ROLES: 'Administrar roles de usuario',
  ARCHIVE_ROLE: 'Rol de archivo',
  ROLE_ARCHIVE_CONFIRMATION: '¿Realmente quieres archivar este rol?',
  ROLES_FOR: 'Funciones para',
  AUDIT_TRAIL: 'Pista de auditoría',
  ACTION_BY_USER: 'Acción del usuario',
  ACTION: 'Acción',
  ORIGINAL_VALUE: 'Valor original',
  CHANGE: 'Cambio',
  CONDITION: 'Condición',
  IP: 'IP',
  GEOLOCATION: 'Geolocalización',
  NEW_VALUE: 'Nuevo valor',
  FILTER_BY_ACTION: 'Filtrar por acción',
  SELECT_DATE: 'Seleccionar fecha',
  TEAM_SAVE: 'Ahorrar',
  TEAM_DELETE: 'Borrar',
  TEAM_CREATE: 'Crear',
  TEAM_CLONE: 'Copiar',
  TEAM_NAME: 'Nombre',
  TEAM_CODE: 'Código',
  TEAM_ACTION_DELETE_CONFIRM: '¿De verdad quieres eliminar',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: '¡Ten cuidado!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'Una vez eliminado, ¡no se puede restaurar!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Respuesta completa en la etiqueta',
  TEAM_RESTRICTED_TEAM: 'Restringida Restringido',
  TEAM_SEGREGATED_DATA: 'Datos segregados',
  TEAM_PRIVATE_TEAM: 'Equipo privado',
  TEAM_AUTO_ASSIGN: 'Asignación automática',
  TEAM_MAILBOX_IN: 'Buzón entrante',
  TEAM_MAILBOX_OUT: 'Buzón saliente',
  TEAM_DEFAULT_LANGUAGE: 'Idioma predeterminado',
  TEAM_COUNTRIES: 'Países asignados',
  TEAM_PRODUCT_GROUPS: 'Asignar grupos de productos',
  TEAM_PARENT: 'Equipo matriz',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Anular el equipo de raíz de clúster',
  TEAM_AFFILIATIONS: 'Equipos afiliados',
  TEAM_TIMEZONE: 'Zona horaria',
  UPDATE: 'Actualizar',
  AUTO_ASSIGN_BY: 'Auto Asignar por',
  AUTO_ASSIGNMENTS: 'Asignaciones automáticas',
  NO_ONE: 'Nadie',
  NOT_APPLICABLE: 'No aplica',
  OVERRIDE_NOTIFICATIONS: 'Anular notificaciones',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Cuando la plantilla del usuario con notificaciones de anulación se establece en el usuario, deshabilitará cualquier configuración de notificación preexistente y habilitará solo la configuración de notificación enumerada en la plantilla del usuario',
  CREATED_MANUALLY: 'Creado manualmente',
  DOCUMENT_TITLE_FOR_WORD:
    'El título de los documentos de Word generalmente se toma de la sección formateada por el título dentro del documento mismo. Si el documento no contiene el título, puede especificarlo aquí. Si no especifica el título aquí y el documento de Word no contiene la sección formateada por el título, entonces la ID del documento se utilizaría para el título.',
  ORDER_BY: 'Ordenar',
  FORMAT: 'Formato',
  SELECT_QUESTION: 'Pregunta seleccionada',
  SELECT_QUESTION_FOR_NOTE: 'Seleccione la pregunta para la cual desea agregar nota',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Agregar nota para una consulta completa',
  REQUESTED_DATE: 'Fecha solicitada',
  UNASSIGNED: 'No asignado',
  BLANK: 'Vacía Vacío',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Respuesta guardada sin correo electrónico',
  INQ_RELATED_TO: 'Consultas relacionadas con',
  QUESTION_TEXT_BEFORE_SPLIT: 'Texto de la pregunta antes de la división',
  SPLIT_MERGE: 'Dividir y fusionar',
  CLARIFICATION_QUESTION: 'Cuestión de aclaración',
  ACTIVE_ON: 'Activamente',
  DISMISS: 'Despedir',
  GO_TO_ENQUIRY: 'Ir a la investigación',
  NO_TEAMS: 'Sin equipo',
  NO_TEAMS_TIP:
    'Contiene usuarios, que no están asignados a ningún equipo. Puede verificar si algunos de ellos son de su equipo y otorgarles el papel apropiado.',
  TASKS: 'Tareas',
  Therapeutic_Areas: 'Áreas terapéuticas',
  Product_Groups: 'Grupos de productos',
  Licensing_Partner: 'Socio de licencia',
  Product_Code: 'Código de producto',
  SELECT_EXISTING_ONE: 'Seleccione uno existente',
  OR: 'o',
  MANAGE_FORM: 'Administrar el formulario',
  EVENT_FORM_ADDRESS: 'Dirección del formulario del evento',
  EVENT_FORM_PREVIEW_WARING:
    'Asegúrese de haber guardado cualquier cambio antes de abrir el formulario del Congreso o copiar su dirección',
  SEARCH_SAVED_SEARCHES: 'Búsqueda de búsquedas guardadas',
  GO: 'Vamos',
  ASSIGN: 'Asignar',
  CERTIFICATION: 'Certificación',
  SORT: 'Clasificar',
  ADD_MAILBOX: 'Agregar buzón',
  UNSET_INCOMING_MAILBOX: 'Buzón entrante inseguro',
  SET_INCOMING_MAILBOX: 'Establecer el buzón entrante',
  UNSET_OUTGOING_MAILBOX: 'Buzón saliente unset',
  SET_OUTGOING_MAILBOX: 'Establecer el buzón saliente',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Todos los equipos con buzón actual',
  ALL_TEAMS_WITH_MAILBOX: 'Todos los equipos con buzón',
  MAILBOX: 'Buzón',
  EMAIL_POLLING: 'Encuesta por correo electrónico',
  ADVANCED_SEARCH: 'Búsqueda Avanzada',
  SELECT_FIELDS: 'Seleccionar campos',
  SEARCH_TERM: 'Término de búsqueda',
  COLUMN_INTERACTION_ID: 'ID de interacción',
  COLUMN_QUESTION_ID: 'ID de pregunta',
  COLUMN_CREATED_ON: 'Creado en',
  COLUMN_LAST_EDITED_DATE: 'Última edición en',
  COLUMN_ASSIGNED_TO: 'Asignar a',
  COLUMN_DUE_DATE: 'Fecha de vencimiento',
  COLUMN_FOLLOW_SENT_DATE: 'Fecha de seguimiento enviada',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Fecha de seguimiento recibida',
  COLUMN_ANSWER_DATE: 'Fecha de respuesta',
  COLUMN_CLOSED_ON_DATE: 'Cerrado en la fecha',
  COLUMN_REOPENED_DATE: 'Fecha de reapertura',
  COLUMN_INSERTED_BY: 'Insertado por',
  COLUMN_LAST_EDITED_BY: 'Último editado por',
  COLUMN_ANSWERED_BY: 'Respondido por',
  COLUMN_APPROVED_BY: 'Aprobado por',
  COLUMN_INT_COMM_USER: 'Usuario de comunicación interna',
  COLUMN_INT_COMM_DATE: 'Fecha de comunicación interna',
  COLUMN_MY_TEAM: 'Mi equipo',
  COLUMN_PQC_RELATED: 'Relacionado con PQC',
  COLUMN_INCOMING_CHANNEL: 'Canal entrante',
  COLUMN_OUTGOING_CHANNEL: 'Canal saliente',
  COLUMN_OFF_LABEL: 'Sin marca',
  COLUMN_HCP_SPEC: 'Especialización HCP +',
  COLUMN_NON_HCP_SPEC: 'Profesión no HCP +',
  COLUMN_KEE: 'Kee',
  COLUMN_HOSPITAL_BASED: 'Con sede en el hospital',
  COLUMN_CITY: 'Ciudad',
  COLUMN_INSTITUTION: 'Institución',
  COLUMN_TERRITORY: 'Territorio',
  CASE_ID: 'Identificación del caso',
  OPTION_ADD: 'AGREGAR',
  OPTION_AND: 'Y',
  OPTION_OR: 'O',
  OPTION_NOT: 'NO',
  OPTION_YES: 'Sí',
  OPTION_NO: 'No',
  QUERY: 'Consulta',
  EXPORT_LABEL: 'Definir columnas de exportación',
  SAVE_SEARCH: 'Guardar búsqueda',
  SHARE_SEARCH: 'Búsqueda de la búsqueda',
  SEARCH_BTN: 'Búsqueda',
  BACK_ACTION: 'atrás',
  SAVE_CHANGES: 'Guardar cambios',
  SAVED_SEARCH: 'Búsqueda guardada',
  NEW: 'Nueva Nuevo',
  SAVE_AS: 'Guardar como nombre',
  ENTER_RECIPIENTS: 'Ingrese a los destinatarios',
  CLEAR_SAVED_SEARCH: 'Borrar búsquedas guardadas',
  NO_SAVED_SEARCH: 'No se encontró una búsqueda guardada',
  INT_COMM_TEXT: 'Texto de comunicación interna',
  FOLLOW_UP_TEXT: 'Texto de seguimiento',
  CONFIRM_DELETION: 'Confirmar la eliminación',
  ARE_SURE: '¿Estás seguro de que quieres eliminar la búsqueda guardada?',
  PERMANENTLY_DELETED: 'La búsqueda se eliminará permanentemente </b>.',
  NOTHING_SAVE: 'Nada para salvar.',
  NOTHING_SEARCH: 'Nada para buscar.',
  NOTHING_EXPORT: 'La búsqueda no tiene registro de exportación.',
  SHARE_SUCCESS: 'La búsqueda se ha compartido con éxito',
  SHARE_FAILURE: 'La búsqueda no se pudo compartir',
  DELETED_SUCCESS: 'Eliminó con éxito la búsqueda guardada',
  ALL_DELETED_SUCCESS: 'Eliminó con éxito todas las búsquedas guardadas',
  VIEW_SAVED_SEARCH: 'Ver búsqueda guardada',
  EDIT_SAVED_SEARCH: 'Editar búsqueda guardada',
  EXPORT_SAVED_SEARCH: 'Búsqueda guardada de exportación',
  EXPORT_SEARCH: 'Búsqueda de exportación',
  DELETE_SAVED_SEARCH: 'Eliminar la búsqueda guardada',
  SELECT_EXPORT_COLUMNS: 'Seleccionar columnas para exportar',
  SAVED_SEARCH_ALREADY_EXIST: 'Buscar con ese nombre ya existe.',
  CHARACTER_LIMIT: 'Min 3 caracteres. Max 35 caracteres.',
  CHARACTER_LEFT: 'Los personajes se quedan',
  ACTIVATE: 'Activar',
  INACTIVE_USER:
    'Este usuario ya no está activo. Por favor, póngase en contacto con el administrador del sistema.',
  FOLLOW_UP: 'Hacer un seguimiento',
  USER_NOT_FOUND: 'Usuario no encontrado',
  DEPARTMENTS: 'Departamentos',
  EMAIL: 'EMAIL',
  ADD_NEW_DEPARTMENT: 'Agregar nuevo departamento',
  DEPARTMENT_COUNTRY: 'PAÍS',
  DEPARTMENT_COMPANY: 'EMPRESA',
  DEPARTMENT_TEAM: 'EQUIPO',
  DEPARTMENT_TA: 'Área terapéutica',
  DEPARTMENT_PRODUCT: 'PRODUCTO',
  DEPARTMENT_ACTIONS: 'COMPORTAMIENTO',
  DEPARTMENT_CONFIRMATION_1: '¿Confirmar la eliminación?',
  DEPARTMENT_CONFIRMATION_2: 'Esta acción es irreversible.',
  DEPARTMENT_NAME: 'Nombre de Departamento',
  TYPE_DEPARTMENT: 'Escribe',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'País - Seleccione el equipo primero',
  DEP_NAME_CHARACTER_LIMIT: 'Max 200 caracteres.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Solicitud del usuario',
      CategoryTopicRequest: 'Solicitud de categoría/tema',
      ProductRequest: 'Petición de productos',
      UserUpdateRequest: 'Solicitud de actualización del usuario',
    },
    TYPE: {
      NewUser: 'Nuevo Usuario',
      NewCategoryTopic: 'Nueva categoría/tema',
      NewUserUpdate: 'Actualización del usuario',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS:
        'Definir la lista de especialización específica del país',
      IS_COUNTRY_SPECIFIC: 'Es específico del país',
      TYPE: 'Tipo de consultante',
      SPECIALIZATION_EXISTS:
        'La configuración para "Tipo de investigador/especialización" ya existe.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Género', COUNTRY: 'País', TITLE: 'Título' },
  },
  hcp: 'HCP',
  emp: 'Empleada Empleado',
  cons: 'No compatible',
  OOO_DEPUTY: 'Ooo diputado',
  NO_DEPUTY: 'No diputado',
  UNSPECIFIED_END_DATE: 'Fecha de finalización no especificada',
  DELEGATORS: 'Delegadores',
  NO_DELEGATORS: 'No hay delegadores',
  PREVIEW_DELEGATORS: 'Delegadores de vista previa',
  REMOVE_DEPUTY: 'Eliminar diputado',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Usuarios que te establecen como diputado',
  CHANGED_USER: 'Usuario cambiado',
  DEFAULT: 'Defecto',
  SPECIALIZATIONS: 'Especializaciones',
  ENQUIRERS_LIST: 'Lista de enquiradores',
  EDIT_THE_RECORD: 'Editar el registro',
  DEACTIVATE_THE_RECORD: 'Desactivar el registro',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Fuera de la oficina',
      HINT: 'Solo los usuarios activos, con el rol de procesamiento de la consulta, que no están fuera de la oficina están disponibles para la selección.',
      MODAL_TITLE: 'Confirmar la configuración fuera de la oficina',
      MODAL_MSG_1:
        'Ha expuesto a la opción de la oficina con efecto inmediato, todas sus consultas serán asignadas inmediatamente al diputado.',
      MODAL_MSG_2:
        'Ha expuesto a la opción de la oficina, todas sus consultas se asignarán al diputado durante el período fuera de la oficina.',
    },
  },
  GENDER_MALE: 'Señor.',
  GENDER_FEMALE: 'Sra.',
  TITLE_DR: 'Dr.',
  TITLE_PHD: 'Doctora Doctor',
  TITLE_PROF: 'Profe.',
  TITLE_PROF_DR: 'Prof. Dr.',
  HOME: 'Hogar',
  HOSPITAL: 'Hospital',
  OFFICE: 'Oficina',
  WORK: 'Trabajar',
  INSERT_IMAGE: 'Insertar imagen',
  USER_ADMIN: 'Administrador de usuarios',
  MAILBOXES: 'Buzones',
  AUTHORIZATION_REQUESTS: 'Solicitudes de autorización',
  METADATA: 'Metadatos',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Asignación',
      FORWARD: 'Delantera Delantero',
      FOLLOW_UP: 'Hacer un seguimiento',
      DUE_DATE_SETTINGS: 'Configuración de fecha de vencimiento',
      CLOSE: 'Cerca',
      DELIVERY_ERROR: 'Error de entrega',
      SCHEDULER: 'Planificador',
      USER_REPORTS: 'Informes de usuario',
      DOCUMENT_APPROVAL: 'Aprobación de documentos',
      AUTHORIZATION_REQUEST: 'Solicitudes de autorización',
      NEW_INQUIRY: 'Nueva consulta',
      INQUIRY_APPROVAL: 'Aprobación de la consulta',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Se aprobó/rechazó una solicitud de roles',
      AUTHORIZATION_REQUEST_3: 'Un papel es esperar mi aprobación',
      DOCUMENT_APPROVAL_1: 'Un documento está esperando su aprobación',
      DOCUMENT_APPROVAL_2: 'Un documento fue aprobado/rechazado',
      DOCUMENT_APPROVAL_3: 'Se archivó un documento',
      DOCUMENT_APPROVAL_4: 'Se aprobó un documento con cambios',
      DOCUMENT_APPROVAL_5: 'Un documento será archivado en',
      DOCUMENT_APPROVAL_6: 'Lista de documentos aprobados',
      DUE_DATE_2: 'Repita después del pasado vencido',
      ERROR_1: 'Falla de notificación de entrega (por ejemplo, correo electrónico incorrecto)',
      FOLLOW_UP_1: 'Se envió un seguimiento o un recordatorio al cliente',
      FOLLOW_UP_4: 'Se recibió un seguimiento',
      IMPORT_DATA_1: 'Se ha creado un nuevo objeto durante la importación',
      IMPORT_DATA_4: 'Se ha creado un nuevo usuario durante la importación',
      IMPORT_DATA_5:
        'Un usuario coincidía con uno ya existente pero no provenía de una importación anterior',
      IMPORT_DATA_6: 'Se produjo un error durante la importación de datos de una fuente externa',
      NEW_DOCUMENT_1: 'El nuevo documento aprobado está disponible en mi país',
      NEW_DOCUMENT_2: 'El nuevo documento está disponible para la aprobación local',
      NEW_DOCUMENT_3:
        'El nuevo documento local está disponible para la aprobación europea / global',
      NEW_DOCUMENT_4: 'El nuevo documento global está disponible',
      NEW_DOCUMENT_5: 'El nuevo documento europeo está disponible',
      NEW_DOCUMENT_6: 'El nuevo documento europeo está disponible para la aprobación global',
      SCHEDULER_TASK_1: 'Ocurrió un error durante la ejecución de una tarea',
      USER_REPORTS_1: 'Lista de usuarios activos',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'Recibir notificaciones informándole cuándo debe aprobarse/rechazar una solicitud de roles de usuario',
        AUTHORIZATION_REQUEST_2:
          'Recibir notificaciones cuando se creó un nuevo usuario o cuando un usuario solicitó un rol en su equipo',
        AUTHORIZATION_REQUEST_4:
          'Recibir notificaciones cuando se sugirió un nuevo tema y/o categoría',
        FOLLOW_UP_4:
          'Recibir notificaciones de consultas a las que estoy asignado que recibieron una respuesta de seguimiento del consultante',
        NEW_INQUIRY_1:
          'Recibir notificaciones de nuevas consultas recibidas en su país, insertadas por un consultante/usuario o reenviadas desde otro equipo',
        ANSWER_2:
          'Recibir notificaciones de consultas que fueron respondidas y en las que trabajé como colaborador, pero no estoy asignado directamente a la consulta.',
        DUE_DATE_1:
          'Al seleccionar esta opción se activarán todas las notificaciones que aparecen a continuación. Para reducir la cantidad de notificaciones, desmarque las que no necesite.',
        INQUIRY_APPROVAL_5:
          'Su respuesta propuesta fue rechazada o el estado de la interacción se actualizó de Enviado para aprobación a En progreso debido a una división mediante interacción, Fusionar como nueva consulta que resultó en una división o cualquier cambio en la configuración del equipo en términos de la necesidad de una aprobación de la consulta posterior.',
        INQUIRY_APPROVAL_4: 'Su propuesta de respuesta fue aprobada.',
        METADATA_MANAGEMENT_2:
          'Recibir notificación sobre productos recién creados independientemente del motivo de su creación.',
        METADATA_MANAGEMENT_3:
          'Recibir notificación de la categoría recién creada independientemente del motivo de su creación.',
        METADATA_MANAGEMENT_4:
          'Recibir notificación sobre temas recién creados independientemente del motivo de su creación.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Instantáneamente',
        DAILY: 'Diariamente',
        WEEKLY: 'Semanalmente',
        QUARTERLY: 'Trimestral',
        MONTHLY: 'Mensual',
        SECOND_DAY: 'Todos los segundos días',
        THIRD_DAY: 'Cada tercer día',
        ONE_WEEK: 'Una semana',
        ONE_MONTH: 'Un mes',
        TWO_MONTHS: 'Dos meses',
        THREE_MONTHS: 'Tres meses',
        SIX_MONTHS: 'Seis meses',
        NINE_MONTHS: 'Nueve meses',
        EVERY_TWO_WEEKS: 'Cada dos semanas',
        EVERY_THREE_WEEKS: 'Cada tres semanas',
        EVERY_FOUR_WEEKS: 'Cada cuatro semanas',
      },
      OPTIONS: {
        ALMOST_DUE: 'Casi debido',
        DUE_NOW: 'Por ahora',
        DUE_SOON: 'Debido pronto',
        EARLY_ON: 'Temprano',
        TEXT_GUIDES: {
          DUE_NOW: 'Recibir notificaciones cuando la consulta esté pendiente',
          EARLY_ON:
            'Reciba notificaciones anticipadas sobre consultas que vencen: Normal 4 días - Alta 2 días - Crítica 24 horas antes de la fecha de vencimiento',
          DUE_SOON:
            'Recibir notificaciones de consultas que vencen pronto: Normal 48 horas - Alta 12 horas - Crítica 6 horas antes de la fecha de vencimiento',
          ALMOST_DUE:
            'Recibir notificaciones de consultas próximas a vencer: Normal 24 horas - Alta 6 horas - Crítica 3 horas antes de la fecha de vencimiento',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Se solicitó una categoría/ tema en mi equipo',
      AUTHORIZATION_REQUEST_2: 'Solicitudes de usuario',
      IMPORT_DATA_2: 'Se ha creado un nuevo consultante durante la importación',
      IMPORT_DATA_3:
        'Un consultante estaba haciendo coincidir uno ya existente pero no provenía de una importación anterior',
      ANSWER_2: 'Una consulta en la que estuve involucrado fue respondida',
      ASSIGNMENT_1: 'Se me asignó una investigación',
      ASSIGNMENT_5: 'Se le asignó una investigación a mi adjunto.',
      CLOSE_1: 'Se cerró una investigación',
      DUE_DATE_1: 'Una consulta que me han asignado está próxima a vencerse, infórmame',
      FOLLOW_UP_7: 'Se perdió una consulta durante el seguimiento',
      FORWARD_1: 'Se envió una consulta',
      INQUIRY_APPROVAL_1: 'Una consulta está esperando su aprobación',
      INTERNAL_1: 'Se agregó una nota a mi consulta asignada',
      NEW_INQUIRY_1: 'Se recibió una nueva consulta en mi país',
      SPAM_1: 'Nueva consulta recibida en Spam',
      ANSWER_1: 'Recibir copia de la respuesta en interacciones respondidas por mi',
      ANSWER_6: 'Recibir copia de la respuesta en interacciones aprobadas por mí',
      INQUIRY_APPROVAL_5: 'Su respuesta propuesta fue rechazada',
      INQUIRY_APPROVAL_4: 'Su propuesta de respuesta fue aprobada',
      METADATA_MANAGEMENT_1: 'Geografía y alineaciones durante la importación de CRM',
      METADATA_MANAGEMENT_2: 'Se ha creado un nuevo producto',
      METADATA_MANAGEMENT_3: 'Se ha creado una nueva categoría',
      METADATA_MANAGEMENT_4: 'Se ha creado un nuevo tema',
    },
  },
  NEW_TEAM: 'Nuevo equipo',
  SmPc: 'SMPC',
  USERS: 'Usuarias Usuarios',
  PAGINATOR_NEXT_PAGE_LABEL: 'Siguiente página',
  PAGINATOR_PREV_PAGE_LABEL: 'Pagina anterior',
  PAGINATOR_FIRST_PAGE_LABEL: 'Primera página',
  PAGINATOR_LAST_PAGE_LABEL: 'Última página',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'elementos por página',
  Date: 'Fecha',
  Action: 'Acción',
  'Action by user': 'Acción por usuario',
  'Changed user': 'Usuario cambiado',
  'Original value': 'Valor original',
  'New value': 'Nuevo valor',
  REGION: 'Región',
  TEAM_REGION_LABEL: 'Región - Seleccione la empresa primero',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Áreas terapéuticas: seleccione primero la empresa',
  TEAM_PRODUCT_LABEL: 'Productos: seleccione primero el área terapéutica',
  TEAM_COUNTRIES_LABEL: 'Países: seleccione primero la región',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Definir productos para cada país',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Seleccione al menos un producto para',
  VIEW_CHANGES: 'Ver cambios',
  Sales_Representative: 'Representante de ventas',
  Other_Commercial: 'Otro comercial',
  REGIONS: 'Regiones',
  MAIN_CLIENT: 'Cliente principal',
  LICENSE_PARTNER: 'Socio de licencia',
  TEAM_SET_UP: 'Establecimiento de equipo',
  LIST_TEAMS: 'Lista de equipos',
  COMPANIES: 'Compañías',
  DOMAINS: 'Dominio',
  TOKEN_REFRESH_NOTICE:
    'Solicitó un nuevo correo electrónico de confirmación para el registro de su cuenta enqmed.',
  TOKEN_REFRESH_SUCCESS: 'Se ha enviado un nuevo enlace de confirmación a su correo electrónico.',
  TOKEN_REFRESH_ERROR:
    'Ocurrió un error. Póngase en contacto con el soporte si necesita más ayuda.',
  PLEASE_ENTER_MAX: 'Por favor ingrese el máximo',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Revise los cambios a continuación, si corresponde, y confirme si la empresa debe cambiarse.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Continúe con precaución porque los cambios afectarán a los equipos',
  THERE_ARE_NO_CHANGES: 'No hay cambios.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique las empresas existentes en la tabla detrás del cuadro de diálogo, ya que ya tenemos la empresa con los mismos valores.',
  SIMPLE_TEXT:
    'Ingrese solo letras (A-Z, A-Z), números (0-9) y símbolos especiales como (Dash, Periodo, Espacio y Colon).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'Seleccione al menos un país para la región',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Seleccione al menos un producto para el área terapéutica',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'Ha seleccionado productos que pertenecen a más de un área terapéutica, seleccione todas las áreas terapéuticas necesarias.',
  HTML_CHANGES: 'Cambios de HTML',
  SIDE_BY_SIDE_VIEW: 'Vista lado a lado',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique los dominios existentes en la tabla detrás del diálogo, ya que ya tenemos dominio con los mismos valores.',
  OUT_CHANNEL_ERROR_MSG: 'Carta saliente del canal de la dirección postal de Enquirer',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Canal saliente seleccionado como letra. Ingrese la dirección postal de Enquirer dentro del área de Enquirer',
  LABEL_FOR: 'Etiqueta para',
  male: 'Masculina Masculino',
  female: 'Femenina Femenino',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'No tienes derecho a archivar este papel. Solo <b> mims </b> para el equipo puede archivar roles para los miembros de su equipo.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'Envíe el correo electrónico al usuario a través de la aprobación/rechazo',
  APPROVE_REQUEST_QUESTION: '¿Quieres aprobar la solicitud?',
  NEW_USER_REQUEST: 'Nueva solicitud de usuario',
  OPEN_REQUESTS: 'Solicitudes abiertas',
  APPROVED_REQUESTS: 'Solicitudes aprobadas',
  REJECTED_REQUESTS: 'Solicitudes rechazadas',
  USER_REQUESTS: 'Solicitudes de usuario',
  REQUEST_FOR: 'Solicitud de',
  CATEGORY_TOPIC_REQUESTS: 'Solicitudes de categoría/tema',
  WITH_THE_FOLLOWING_PAIRS: 'Con los siguientes pares:',
  MY_TEAM_OPEN_REQUESTS: 'Solicitudes abiertas',
  MY_TEAM_APPROVED_REQUESTS: 'Solicitudes aprobadas',
  MY_TEAM_REJECTED_REQUESTS: 'Solicitudes rechazadas',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Aprobación de documentos dentro de EnqMed',
  DOCUMENTS_APPROVAL: 'Aprobación de documentos',
  DOCUMENTS_APPROVAL_TITLE:
    'Configuración para el proceso de aprobación de documentos para el equipo seleccionado',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Canal saliente seleccionado como fax. Ingrese el número de fax de Enquirer dentro del área de Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Canal saliente seleccionado como teléfono. Ingrese el número de teléfono de Enquirer dentro del área de Enquirer',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Valor de fax de canal saliente Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Valor del teléfono del canal saliente Enquirer',
  COPYRIGHT_PART_ONE: 'Derechos de autor',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Todos los derechos reservados.',
  REVIEWED_DATE: 'Fecha revisada',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Canal saliente seleccionado como correo electrónico. Ingrese el correo electrónico de Enquirer dentro del área de Enquirer',
  OUT_CHANNEL_EMAIL_ERROR_MSG:
    'Correo electrónico saliente Correo electrónico del valor de Enquirer',
  ENTER_USER_COMMENT_OPTIONAL: 'Ingrese el comentario al usuario (opcional)',
  SELECT_ANOTHER_CATEGORY: 'Seleccione otra categoría',
  SELECT_ANOTHER_PRODUCT: 'Seleccione otro producto',
  SUGGESTED_TOPIC: 'Tema sugerido',
  EDIT_USER_REQUEST: 'Editar solicitud de usuario',
  NEW_TOPIC_REQUEST: 'Nuevo tema sugerido',
  EDIT_TOPIC_REQUEST: 'Editar tema sugerido',
  NEW_CATEGORY_REQUEST: 'Nueva categoría sugerida',
  EDIT_CATEGORY_REQUEST: 'Editar categoría sugerida',
  REVIEW_COMMENT: 'Comentario de revisión',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Flujos de trabajo de aprobación de documentos',
    CREATE_NEW: 'Crear un nuevo flujo de trabajo',
    CUSTOMIZE: 'Personalizar el flujo de trabajo',
    NAME: 'Nombre de flujo de trabajo',
    ERROR_MSG_DUPLICATE_NAME: 'El flujo de trabajo con este nombre ya existe.',
    SAVE: 'Guardar flujo de trabajo',
    DELETE: 'Eliminar flujo de trabajo',
    STEP: 'Paso',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Todos los usuarios con el rol de autor del documento.',
    APPROVAL_DEADLINE: 'Fecha límite de aprobación',
    SELECT_APPROVAL_TYPE: 'Seleccione el tipo de aprobación',
    SELECT_APPROVAL_TYPE_FIRST: 'Seleccione el tipo de aprobación primero',
    SELECT_REVIEWERS: 'Seleccionar revisores',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Acción sobre aprobar con cambios',
    ACTION_ON_REJECT: 'Acción sobre rechazo',
    FILL_ALL_REQUIRED_FIELDS: 'Complete todos los campos requeridos para agregar un nuevo paso.',
    ALL_APPROVAL_TYPES_USED:
      'No puede agregar más pasos de aprobación, ya que utilizó todos los tipos disponibles para el paso actual.',
    CONFIRMATION_ROW_1:
      'Está intentando eliminar el flujo de trabajo de aprobación de documentos para el equipo {{ NAME }}.',
    CONFIRMATION_ROW_2: '¿Realmente quieres eliminar el flujo de trabajo de aprobación?',
    CONFIRMATION_TITLE: 'Eliminación de flujo de trabajo de aprobación del documento',
    TEAM_VIEW_SELECT_EXISTING:
      'Seleccione un flujo de trabajo de aprobación de documentos existente de la lista a continuación o ',
    DOC_VIEW_SELECT_EXISTING:
      'Seleccione un flujo de trabajo de aprobación de documentos existente',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Solo el paso de aprobación única se puede enumerar como final.',
    APPROVAL_TYPE_TOOLTIP:
      'No hay usuarios con papel de aprobación de documentos para este equipo. Agregue los roles de aprobación primero.',
    INFO_DIALOG_TITLE:
      'No hay un flujo de trabajo de aprobación de documentos establecido para este equipo.',
    INFO_DIALOG_MSG:
      'Póngase en contacto con su MIM local para crear un flujo de trabajo de aprobación de documentos para el equipo.',
    STEP_LIMIT_REACHED: 'Ha alcanzado el límite de {{ MAX_STEPS }} pasos de aprobación.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Tipos de documentos que no requieren aprobación',
  PLEASE_SELECT_TYPE: 'Seleccione Tipo',
  ABORT_FILE_UPLOAD: 'Abortar la carga de archivo',
  USERS_REPORT: 'Informe de usuarios',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Al cerrar esta ventana, perderá la información que ya se completó.',
  DO_YOU_WANT_TO_PROCEED: 'Quieres proceder?',
  USERS_REPORT_DESCRIPTION:
    'La lista de usuarios de todos los equipos adicionales donde MIM para Daiichi Sankyo Europe Oncology and Cardiovascular tiene un papel MIM.',
  TEMPLATE_CONFIRM_MSG: 'Una vez que se elimina la plantilla, ¡no se puede restaurar!',
  EVENT_CONFIRM_MSG: 'Una vez que el evento se archiva, ¡no se puede restaurar!',
  CREATE_DRAFT: 'Crear borrador',
  CREATE_USER: 'Crear usuario',
  ADD_ROLE: 'Agregar rol',
  DOMAIN_ADDRESS_INVALID_FOR: 'Dirección de dominio inválida para',
  AVAILABLE_ADDRESSES: 'Direcciones disponibles',
  IN_TEAM: 'en el equipo',
  TO_THE_SELECTED_USER: 'al usuario seleccionado',
  USER_INFORMATION: 'Informacion del usuario',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'La dirección de correo electrónico que está utilizando debe actualizarse ya que la dirección de dominio no corresponde al equipo/s que su perfil está asociado. <BR> Consulte a su administrador del sistema para obtener ayuda o comuníquese con el equipo de soporte.',
  MESSAGE: 'Mensaje',
  IN_APPROVAL: 'En aprobacion',
  IN_REVIEW: 'En revisión',
  DOCUMENT_APPROVAL: 'Aprobación de documentos',
  DOCUMENT_TITLE: 'Titulo del documento',
  SEARCH_DOCUMENTS: 'Documentos de búsqueda',
  APPROVAL_INBOX: 'Bandeja de entrada de aprobación',
  FILTER_DOCUMENTS_BY: 'Filtrar documentos por',
  submit_to_approval: 'Enviar a la aprobación',
  create_document_metadata: 'Crear metadatos de documento',
  archive: 'Archivo',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'Reconozco que es mi obligación informar todas las reacciones adversas a los medicamentos (ADR) de todos los productos médicos de acuerdo con los requisitos locales. EnqMed no tiene la intención de capturar informes sobre reacciones adversas a los medicamentos: los ADR deben informarse a la farmacovigilancia directamente de acuerdo con los requisitos locales',
  VERSION_DIALOG: {
    TITLE: 'Nueva versión disponible',
    MESSAGE:
      '¡Hay una nueva versión del software disponible!\n Debe obtener la nueva versión, para beneficiarse de las mejoras y evitar problemas al usar la versión anterior, debe obtenerla.',
    SAVE_BUTTON: 'Obtenga la nueva versión',
  },
  ACTIVE_INACTIVE: 'Activo inactivo',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique las categorías existentes en la tabla detrás del cuadro de diálogo, ya que ya tenemos una categoría con los mismos valores.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: '¡Error! Entrega Fallida',
  VIA: 'a través de',
  INSERTED_MANUALLY: 'Insertado manualmente',
  AS_A: 'como un',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique los productos existentes en la tabla detrás del cuadro de diálogo, ya que ya tenemos productos con los mismos valores.',
  NOT_PRODUCT_SPECIFIC: 'No específico del producto',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique los temas existentes en la tabla detrás del cuadro de diálogo, ya que ya tenemos temas con los mismos valores.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Introduzca un máximo de {{ MAX_CHARS }} caracteres.',
  THANK_YOU_MESSAGE: 'mensaje de agradecimiento',
  AS: 'como',
  THERAPEUTIC_AREA_PARENT: 'Área de padres',
  MAIN_THERAPEUTIC_AREA: 'Área Terapéutica Principal',
  CHOOSE_AN_IMAGE: 'Elige una imagen',
  ALLOWED_FORMATS: 'Solo se permiten formatos {{ FORMATS }}',
  REMOVE_IMAGE: 'Quita la imagen',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique las áreas terapéuticas existentes en la tabla detrás del cuadro de diálogo, ya que ya tenemos áreas con los mismos valores.',
  PLAIN_TEXT_VIEW: 'Vista de texto sin formato',
  RICH_TEXT_VIEW: 'Vista de texto enriquecido',
  EDIT_ITEM: 'Editar {{ ITEM }}',
  CREATE_NEW_ITEM: 'Crear nuevo {{ ITEM }}',
  ADD_NEW_ITEM: 'Agregar nuevo {{ ITEM }}',
  ADD_ITEM: 'Añadir {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'y por lo tanto el estado cambió de',
  FOLLOW_UP_WITH_ENQUIRER: 'Seguimiento con el solicitante',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'Se debe insertar lo siguiente para guardar los cambios:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'El seguimiento no es posible para una interacción cerrada.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interacción en estado de revisión. Guarde la reseña para habilitar este menú.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Texto de la pregunta (mínimo 6 caracteres)',
  PLEASE_PROVIDE_AN_ANSWER_:
    'Proporcione una respuesta (mínimo 6 caracteres o un archivo adjunto).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    'Tenga en cuenta que el usuario debe recuperar la contraseña de "Olvidé mi contraseña".',
  ENABLE_USER_LOGIN_MESSAGE: 'Esto permitirá el inicio de sesión del usuario.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Esto deshabilitará el inicio de sesión del usuario y las notificaciones.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    '¡Configure sus preferencias de notificación desde su perfil de usuario!',
  SET_PASSWORD: 'Por favor establezca su contraseña',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Después de la reactivación de su cuenta, su contraseña fue borrada.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: '¡Confirma la desactivación de la especialización!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Esto lo eliminará de la lista con especializaciones activas.',
  GO_TO_ROLES: 'Ir a roles',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Sus cambios en la información de contacto del usuario provocaron el cambio del correo electrónico de inicio de sesión de {{ OLD_USERNAME }} a {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Asigne roles y notificaciones.',
  DO_YOU_WANT_TO_CONTINUE: '¿Quieres continuar?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Copiar respuesta a la nueva interacción.',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Se han importado nuevas consultas. Asegúrese de caracterizar y responder todas las consultas.',
  LEAVE_WITHOUT_SAVING: 'Salir sin ahorrar',
  PENDING_CHANGES_MESSAGE: '¿Los cambios pendientes son {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    '¿Está seguro de que desea dividir el texto seleccionado en una nueva interacción?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Esto eliminará el texto seleccionado de la interacción y creará uno nuevo a partir de él.',
  DOWNLOAD_ERROR: '¡Error de descarga!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Está intentando descargar más de 1000 consultas. El número máximo admitido es 1000. Por favor refine su selección.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    '¡Estás intentando cambiar la caracterización de las preguntas!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Tenga en cuenta que cambiar la caracterización de las preguntas no permitirá ningún cambio en la respuesta dada anteriormente ni permitirá enviar una nueva respuesta.',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'Estás a punto de reabrir una interacción cerrada para enviar una nueva respuesta.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    '¿Está seguro de que desea convertir este seguimiento en una pregunta?',
  ARE_YOU_SURE: '¿Está seguro?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Confirmar la desactivación de {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Confirmar la reactivación de {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: '¡El usuario {{ USER_INFO }} se reactivó exitosamente!',
  DELETE_NOT_POSSIBLE: '¡No es posible eliminar!',
  SEARCH_NO_LONGER_EXIST: 'Esta búsqueda ya no existe porque el remitente la eliminó.',
  LOGIN_CREDENTIALS_CHANGED: '¡Las credenciales de inicio de sesión cambiaron!',
  EDIT_TEXT: 'Editar texto',
  FOLLOW_UP_ATTEMPTS: 'Intentos de seguimiento',
  FIRST_FOLLOW_UP_INFO_TEXT: 'Primer seguimiento enviado dentro de la interacción individual.',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Si alguno de los siguientes se desactiva después de la solicitud inicial, el proceso pasará al siguiente paso',
  SECOND_ATTEMPT: 'Segundo intento (primer recordatorio)',
  THIRD_ATTEMPT: 'Tercer intento (primer recordatorio)',
  FINAL_ATTEMPT: 'Perdido durante el seguimiento (cerrar)',
  REMINDER_FREQUENCY: 'Frecuencia de recordatorio',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Aplicar traducción automática al idioma utilizado para contactar al solicitante.',
  ON_OFF_SWITCH: 'Encendido apagado',
  DAY: 'Día',
  DAYS: 'Días',
  MONTH: 'Mes',
  MONTHS: 'Meses',
  RESET_TEMPLATES_TO_DEFAULTS: 'Restablecer plantillas a los valores predeterminados',
  SAVE_FREQUENCY: 'Guardar frecuencia',
  SELECT_FREQUENCY: 'Seleccionar frecuencia',
  TEMPLATE_MODIFICATIONS_HINT:
    'El texto se ha modificado manualmente y ya no se cambiará automáticamente. Haga clic aquí para descartar las modificaciones y volver a habilitar las actualizaciones automáticas.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: '¡Dirección de E-Mail incorrecta!',
  AUDIT_TRAIL_FOR: 'Pista de auditoría para {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Seguimiento de auditoría para todos los {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'Cambios históricos para',
  HOLIDAY_GDPR_DISCLAIMER: 'Descargo de responsabilidad del RGPD durante las vacaciones',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER:
    'Gestionar el descargo de responsabilidad del RGPD durante las vacaciones',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'Falta la plantilla GDPR de vacaciones para los siguientes equipos',
  BULK_CREATE: 'creación masiva',
  LAST_SUCCESS: 'Último éxito',
  LAST_FAILURE: 'último fracaso',
  FAILURE: 'Falla',
  TASK: 'Tarea',
  Weeks: 'Semanas',
  REPEAT_EVERY: 'Repetir en cada',
  DEACTIVATE_ITEM: 'Desactivar {{ ITEM }}',
  RESET_FAILURE_COUNT: 'Restablecer recuento de errores',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique las tareas existentes en la tabla detrás del cuadro de diálogo, ya que ya tenemos tareas con los mismos valores.',
  ACTIVATE_ITEM: 'Activar {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Ingrese un número mayor que {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'La interacción de destino seleccionada para la fusión ya está cerrada. Esta consulta es una interacción nueva y no es necesario fusionarla.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Se agregarán nuevas preguntas a la consulta existente; se deben responder todas las preguntas. Seleccione preguntas para fusionar.',
    VALIDATION_MSG:
      'Seleccione al menos una pregunta de la interacción fusionada para continuar con la fusión.',
  },
  MERGED_INTERACTION: 'Interacción fusionada',
  TARGET_INTERACTION: 'Interacción objetivo',
  MERGE_SUGGESTION: 'Sugerencia de fusión',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Revise la sugerencia de fusión a continuación',
  INQUIRERS_COMPARISON_TITLE:
    'Tenga en cuenta que hay diferentes solicitantes para la interacción fusionada y la interacción de destino.',
  INQUIRERS_COMPARISON_QUESTION: 'Confirme si desea continuar a pesar de esta diferencia.',
  TERRITORY: 'Territorio',
  DOMAIN: 'Dominio',
  IN: 'en',
  TEAM_GROUP_FILTER: {
    TITLE: 'Definir permisos para ver equipos adicionales',
    SAVE_BTN_TOOLTIP:
      'Ingrese todos los campos obligatorios para el equipo y guarde el equipo primero.',
  },
  ADR_REF_ID: 'ID de referencia ADR',
  PQC_REF_ID: 'ID de referencia de PQC',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS:
    'Este apego está presente en múltiples interacciones.',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Seleccione la opción de casilla de verificación si desea eliminar el archivo adjunto de todas las interacciones.',
  CONFIRM_DELETE_ATTACHMENT: 'Confirme si desea eliminar este archivo adjunto.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE:
    'Eliminar de todas las interacciones mencionadas anteriormente',
  ATTACHMENT_DELETED: 'Adjunto eliminado',
  ROLES: 'Roles',
  SET_TO_ALL_INQ_TOOLTIP:
    'Establezca la misma caracterización ADR, PQC y no autorizada para todas las preguntas.',
  TASKS_LIST: 'Lista de tareas',
  TASK_LOGS: 'Registros de tareas',
  SELECTED: 'Seleccionado',
  LICENSE_COUNTER: 'Contador de licencias',
  LICENSES_LIMIT: 'Límite de números de licencia',
  USERS_TO_EXCLUDE: 'Usuarios a excluir',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Tarifas de licencia contratadas (roles de usuario).',
  SELECT_USER_ROLES_TO_COUNT:
    'Seleccione los roles de usuario aplicables para el recuento de tarifas de licencia.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Excluir a los usuarios del conteo de tarifas de licencia.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Notificaciones de recibos y ventas.',
  REFRESH_MIGRATION_ERRORS: 'Actualizar la lista de errores de migración',
  MARKED_AS_RESOLVED_BY: 'Marcado como resuelto por',
  MARKED_AS_RESOLVED_ON: 'Marcado como resuelto en',
  MARK_AS_RESOLVED: 'marca como resuelto',
  ERROR_ON: 'Error activado',
  TEXT_WITH_SPECIAL_CHARS:
    'Ingrese solo letras (a-z, A-Z), números (0-9) y símbolos especiales como guión (-), punto (.), espacio, dos puntos (:), coma (,), paréntesis (()), adelante. barra diagonal (/) y más (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'La interacción está en estado enviado para aprobación. Apruebe/rechace para habilitar este menú.',
  REFRESH_TASK_LOGS: 'Actualizar la lista de registros de tareas',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    '¡Por favor confirme si desea continuar con los cambios!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'El archivo manual no está permitido para roles suplentes.',
  RUN_COUNTER: 'Ejecutar contador',
  EXPORT_LICENSES: 'Licencias de exportación',
  LICENSE_COUNT_MESSAGE: 'El número de licencias utilizadas es:',
  QUESTIONS: 'Preguntas',
  CLARIFICATION: 'Aclaración',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Seleccione al menos una pregunta en la interacción de destino para continuar con la combinación.',
    DESCRIPTION:
      'La pregunta de aclaración se agregará debajo de las preguntas seleccionadas en la interacción de destino.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Seleccione esta opción para marcar la interacción como un duplicado de la interacción existente.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Se detectó fusión anterior como pregunta aclaratoria. Todos los campos de respuesta seleccionados, además de los campos de respuesta seleccionados previamente en la interacción de destino, se volverán a abrir para recibir respuesta.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Se reabrió la interacción para dar respuesta a otra pregunta. La respuesta a esta pregunta ya se proporcionó anteriormente.',
  TIP: 'Consejo',
  MERGE_TIP_TEXT:
    'Si desea volver a abrir la interacción {{ MERGED_INTO_ID }}, presione no e inicie un nuevo proceso de combinación indicando la interacción objetivo {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'Está seleccionando una operación de fusión sin abrir la interacción de destino.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'Está seleccionando una operación de combinación en una interacción de destino no válida {{ TARGET_INTERACTION_ID }}.<br>La interacción {{ TARGET_INTERACTION_ID }} se cerró con el estado {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Esto resultará en una interacción cerrada y la incapacidad de dar una respuesta.',
  INTO_INTERACTION: 'en interacción',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Seleccione la interacción para fusionar.',
  PLEASE_SELECT_MERGE_REASON: 'Seleccione el motivo de la fusión.',
  QUESTION_CANNOT_BE_DELETED:
    'Esta pregunta no se puede eliminar ya que hay una pregunta aclaratoria asociada sin respuesta.',
  MERGE_REASON: 'Razón de fusión',
  WITH_REOPEN: 'con reapertura',
  MERGED_INTO_QUESTION_IDS: 'fusionado con los ID de la pregunta',
  MERGED_INTO_QUESTIONS: 'fusionado en pregunta(s)',
  MERGED_PREVIOUSLY_FROM: 'fusionado anteriormente de',
  FROM_INTERACTION_ID: 'desde el ID de interacción',
  IN_INTERACTION_ID: 'en ID de interacción',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'Estás a punto de cancelar la sugerencia de fusión.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: '¿No volver a mostrar la sugerencia de fusión?',
  PRODUCT_RESOLVED_VIA_AI: 'Producto (resuelto mediante AI)',
  REQUIRED_FIELDS_HINT: 'Por favor llene todos los campos requeridos',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: '¡Se requiere al menos un contacto!',
  DISCLAIMER_INFO: 'Información de descargo de responsabilidad',
  QUESTION_ID: 'ID de pregunta',
  QUESTION_IDS: 'ID de preguntas',
  SHOW_TEAMS: 'Mostrar equipos',
  LAST_POLLING: 'Última encuesta',
  HOST: 'Anfitrión',
  PROTOCOL: 'Protocolo',
  FROM_EMAIL: 'Desde el e-mail',
  REASON_FOR_REMOVAL: 'Motivo de Retiro',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'Una interacción cerrada no es editable.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'La interacción tiene más de una pregunta. La edición es posible únicamente desde la interacción abierta.',
  BULK_EDIT: 'Edición masiva',
  HOW_TO_ENABLE_BULK_EDIT:
    'Se deben seleccionar al menos dos plantillas en el mismo idioma para realizar una actualización masiva.',
  REASON_FOR_UPDATE: 'Razón de la actualización',
  FOLLOW_UP_RESEND: 'Seguimiento reenviado',
  SENT_FOLLOW_UP_REMINDER: 'recordatorio de seguimiento enviado',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'interacción cerrada y enviada notificación de pérdida de seguimiento',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS:
    'Por favor ingrese texto con una longitud mínima de 15 caracteres.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    '¡Seguimiento desactivado! Por favor inserte primero al solicitante.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    '¡Solicitud de seguimiento deshabilitada! Por favor inserte primero el correo electrónico válido del solicitante.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'El número para el tipo de frecuencia seleccionado debe estar entre 1 y {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Por favor seleccione el tipo de frecuencia.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'El conteo comienza a partir de la fecha y hora de la solicitud de seguimiento inicial. Para convertir días a semanas, multiplique por 7, p.e. 2 semanas = 14 días, para días o meses multiplique por 30, p.e. 1 mes = 30 días.',
  VALID_PHONE_FAX: '¡Por favor introduzca un número de teléfono/fax válido!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Seleccione un idioma para ver y administrar las plantillas traducidas',
  RETRANSLATE: 'Volver a trasladar',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Las plantillas a continuación están solo en inglés, las traducciones se gestionan a continuación',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Todas las plantillas anteriores se restablecerán a sus valores predeterminados (se eliminará la modificación manual, se restablecerá la frecuencia a 14 días y se deseleccionará la casilla de traducción), se volverán a traducir a todos los idiomas y se borrará is_manually_modified.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Edite el texto de la plantilla y vuelva a traducir todas las traducciones que no se modifiquen manualmente.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Vuelva a traducir el texto de la plantilla original, incluidos los modificados manualmente.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Plantillas de recordatorios de seguimiento',
  MANUALLY_MODIFIED: 'Modificado manualmente',
  SYNC: 'Sincronizar',
  DIFFERENT: 'Diferente',
  DEFAULT_CONFIRMATION_QUESTION: 'Confirme si desea continuar.',
  SEND_WELCOME_MAIL_: 'Enviar correo electrónico de bienvenida con restablecimiento de contraseña',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Confirmo que el consultante recibió una nueva información de seguimiento y me gustaría reabrir la interacción para brindar una respuesta.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Por favor, introduzca sólo letras, espacios y el símbolo -!',
  INFO: 'Información',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'El cambio en el país del consultante cambiará la asignación de la interacción del equipo {{ CURRENT_TEAM }} al equipo {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'El cambio de país del consultante cambiará la asignación de la interacción del equipo {{ CURRENT_TEAM }} al equipo {{ FUTURE_TEAM }}. El usuario {{ ASSIGNED_TO }} no tiene permisos para el equipo {{ FUTURE_TEAM }} y se eliminará su asignación personal.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'El cambio afectará las asignaciones de equipo en las siguientes interacciones adicionales, donde se eliminarán las asignaciones personales: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'El cambio en el país del consultante cambiará las asignaciones de equipo en las siguientes interacciones adicionales, donde se eliminará la asignación personal. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'ID de interacción: {{ INTERACTION_ID }} de {{ CURRENT_TEAM }} a {{ FUTURE_TEAM }}, se eliminó la asignación personal del usuario {{ ASSIGNED_TO }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'El usuario {{ EDITING_USER }} cambió el país del consultante {{ ENQUIRER }} en el ID de interacción: {{ UPDATED_INTERACTION_ID }}, lo que cambió la asignación de la interacción actual del equipo {{ OLD_TEAM }} al equipo {{ NEW_TEAM }}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'El usuario {{ EDITING_USER }} cambió el país del consultante {{ ENQUIRER }} en la administración de HCP, lo que cambió la asignación de la interacción actual del equipo {{ OLD_TEAM }} al equipo {{ NEW_TEAM }}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'El usuario {{ INTERACTION_ASSIGN_TO }} no tiene permisos para el equipo {{ NEW_TEAM }} y se elimina su asignación personal.',
  PERSONAL_ASSIGNMENT: 'Tarea personal',
  DUE_TO_FU_REQUEST: 'debido a solicitud de seguimiento',
  DUE_TO_FU_RESPONSE: 'debido a la respuesta de seguimiento',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'Debido a la actualización de la frecuencia de los recordatorios de seguimiento desde la página de administración',
  UPDATED_FROM_INBOX: 'actualizado desde la bandeja de entrada',
  ARCHIVED_ROLE: 'Función archivada',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Archivar el rol del usuario cambiará las asignaciones en las siguientes interacciones adicionales, donde se eliminarán las asignaciones personales: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'El usuario {{ EDITING_USER }} editó el usuario {{ INTERACTION_ASSIGN_TO }} y archivó su rol como {{ USER_ROLE }} en el equipo {{ TEAM }}. El usuario {{ INTERACTION_ASSIGN_TO }} no tiene permisos para el equipo {{ TEAM }} y se eliminó su asignación personal.',
  ASSIGNMENT: 'Asignación',
  PERIOD_TO_PERIOD_OVERLAP: 'El período y el período hasta el período se superponen',
  PERIOD_TO_PERIOD_MISMATCH:
    'El período y el período final tienen diferente duración: {{ PERIOD }} {{ INTERVAL }} y {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'Pregunta creada a partir de la respuesta de seguimiento',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Esta opción permite crear una nueva pregunta a partir de un seguimiento.',
  ADD_AS_A_NEW_QUESTION: 'Agregar como nueva pregunta',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Tenga en cuenta que está intentando archivar el último rol aprobado para este usuario, lo que provocará la desactivación del usuario y evitará su acceso futuro a EnqMed.',
  ATTEMPTED_FOLLOW_UP: 'Intentó un seguimiento',
  FAILED_FU_ATTEMPT: 'Intento de seguimiento fallido',
  UNABLE_TO_REACH_ENQUIRER: 'No fue posible contactar al consultante.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Seleccione esta opción si intentó comunicarse con el consultante pero no tuvo éxito, por ejemplo, no pudo comunicarse por teléfono, falló la cita presencial u otra cita.\nEl intento quedará registrado en el historial de interacciones.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Serás redirigido a una página para la cual tienes permisos.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    'debido a que el proceso de seguimiento se detuvo como resultado del cambio del consultante',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Estás a punto de cambiar de interlocutor, lo que detendrá el proceso de seguimiento.',
  INQUIRER_DETAILS: 'Detalles del consultante',
  INQUIRER_TYPE: 'Tipo de consultante',
  DELETE_INQUIRER_PII: 'Eliminar información personal del consultante',
  MARKED_INQUIRER_PII_DELETE: 'Información personal del consultante marcada para su eliminación',
  UNMARKED_INQUIRER_PII_DELETE:
    'Información personal del consultante no marcada para su eliminación',
  NEW_ENQUIRER: 'Nuevo consultante',
  NEW_ENQUIRER_TOOLTIP:
    'Utilice esta opción para crear un consultante completamente nuevo. Si, en cambio, es necesario editar los datos del consultante existente, no utilice esta opción.',
  CONFIRM_SET_NEW_ENQUIRER:
    'Por favor, confirme que desea reemplazar el consultante existente por uno nuevo.<br>Se perderá toda la información ingresada.<br>Si en cambio desea editar los datos del consultante existente, modifique los datos directamente en el formulario y presione Guardar.',
  INQUIRER: 'Investigador',
  KEEP_INQUIRER_DETAILS_OPEN: 'Mantenga abiertos los detalles del solicitante',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Tiene cambios sin guardar, que se perderán si cierra los detalles del consultante.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Compartir: desde aquí puedes asignar, reenviar o iniciar un seguimiento con el consultante.',
  UPDATED_INQUIRER_DETAILS: 'Datos actualizados del consultante',
  CREATED_INQUIRER: 'investigador creado',
  SUGGESTED_INQ: 'Investigadores sugeridos',
  SELECT_INQUIRER: 'Seleccionar Enquirer',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'Se debe seleccionar el consentimiento de obligación de ADR y el consultante para poder enviar la interacción',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'La respuesta no se ha enviado al consultante debido a una dirección de correo electrónico incorrecta.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    '¡Gracias! La notificación se enviará una vez que se envíe o responda la consulta.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    '¡Gracias! La notificación se enviará una vez que se haya guardado la consulta.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'La siguiente notificación GDPR se enviará al consultante por correo electrónico.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'La siguiente notificación GDPR ha sido enviada al solicitante por correo electrónico.',
  SEND_A_NOTIFICATION_TO_THE_INQ:
    'Envíe la siguiente notificación GDPR al solicitante por correo electrónico.',
  INQ_HAS_NO_EMAIL: 'El consultante no tiene correo electrónico.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'NO se ha enviado una notificación al consultante de que se ha recibido la consulta.',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Notificación enviada al consultante de que se ha recibido la consulta.',
  THIS_IS_JUST_A_THANK_YOU:
    'Este es simplemente un mensaje de agradecimiento del consultante de la consulta existente.',
  SAVE_INQUIRER: 'Guardar Enquirer',
  INQ_DEFAULT_VAL: 'Valores predeterminados de Enquirer',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'Para consultas enviadas a través de un representante de la empresa (representante de ventas, MSL u otro), notifique al consultante sobre el manejo de sus datos personales.',
  SELECT_PREFERRED_CONTACT_ERROR: 'Debe seleccionar un contacto preferido para el consultante.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Seguimiento iniciado por el consultante',
  NO_INQUIRER: 'Sin consulta',
  'Changed inquirer': 'Se modificó el consultante',
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'La nueva pregunta requerirá una respuesta para cerrar la investigación.',
  IMAGE: 'Imagen',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'El Área Terapéutica es {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', ya que no hay productos asociados. Por favor, seleccione al menos un producto para activar el área terapéutica.',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Ampliar todas las áreas terapéuticas',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Contraer todas las áreas terapéuticas',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'Para establecer un Área Terapéutica (AT) como AT principal, deseleccione la AT principal y guarde.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Se requiere un paso adicional para iniciar sesión',
  EXTRA_STEP_DESCRIPTION:
    'Se ha enviado a tu correo electrónico la confirmación de inicio de sesión. Por favor, sigue los pasos indicados para continuar con el sistema.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Introduzca un texto con una longitud mínima de 15 caracteres o un archivo adjunto.',
  REASON_FOR_REJECTION: 'Motivo del rechazo',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'La nota se ha insertado correctamente en el historial.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'Al {{ ACTION_TYPE }} seleccionar el {{ METADATA_TYPE }}, se aplicarán los siguientes cambios adicionales relacionados:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'Al eliminar los productos, se aplicarán los siguientes cambios adicionales relacionados:',
  TEAM_NOTIFY_INQUIRER: 'Notificar al consultante',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'Al habilitar esta opción, se enviarán automáticamente notificaciones GDPR a los consultantes en interacciones abiertas en este equipo que aún no hayan sido notificadas.',
  TEAM_NOTIFY_SALES: 'Notificar ventas',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'Al habilitar esta opción, se activará la notificación de respuesta involucrada para todos los usuarios con el rol de ventas en este equipo.\n\nAl deshabilitar esta opción, se desactivará la notificación de respuesta involucrada para todos los usuarios con el rol de ventas en este equipo, a menos que tengan el rol de ventas en otro equipo con la configuración de notificación de ventas habilitada.',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'recibe la siguiente notificación',
  EMAILS: 'Correos electrónicos',
  PHONES: 'Teléfonos',
  USERS_DEACTIVATED_SUCCESSFULLY: 'Usuario/s desactivados exitosamente',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'Confirmar desactivación masiva de usuarios',
  DEACTIVATE_SELECTED_USERS: 'Desactivar usuarios seleccionados',
  SELECT_USERS_TO_DEACTIVATE: 'Seleccionar usuarios a desactivar',
  ARCHIVE_FOLDER: 'Carpeta de archivo',
  EXTRA_PARAMS:
    "Parámetros adicionales (k1=v1&k2=v2, 'carpetas' y 'archivo' están reservados y no se pueden utilizar)",
  POLL_FOLDER: 'Carpeta de encuesta',
  POLL_FOLDERS_TEXT:
    'Las carpetas de encuesta solo se pueden configurar para los protocolos IMAP o Gmail. Para todos los demás protocolos, la carpeta predeterminada es la bandeja de entrada.',
  POLL_FOLDERS_TOOLTIP_1:
    'Escriba el nombre de la carpeta y presione Entrar o use una coma para agregarla para sondear desde el buzón (si no se agrega ninguna carpeta, se sondeará la Bandeja de entrada de manera predeterminada).',
  POLL_FOLDERS_TOOLTIP_2: 'Los nombres de carpetas enumerados aquí se sondearán desde el buzón.',
  PERMISSION: 'Permiso',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Verifique los roles existentes en la tabla detrás del diálogo, ya que ya tenemos un rol con el mismo nombre.',
  CURR_MAILBOX: 'Buzón actual',
  ENQUIRY_ANSWER_APPROVAL: 'Aprobación de la respuesta de la consulta',
  REASON_FOR_INCLUSION: 'Motivo de la inclusión',
  FOLDERS_TO_POLL_FROM_MAILBOX: 'Carpetas para sondear desde el buzón',
  VERIFY_SETUP: 'Verificar configuración',
  HTML_PREVIEW: 'Vista previa HTML',
  IN_REPLY_TO_ID: 'En respuesta a id',
  INCOMING: 'Entrante',
  OUTGOING: 'Extrovertido',
  VIEW: 'Vista',
  ROLE_IN_USE_USERS:
    'Actualmente, el rol está asignado a los usuarios y archivarlo puede limitar sus derechos de acceso.',
  ROLE_IN_USE_USER_TEMPLATES:
    'Actualmente, el rol lo utilizan las plantillas de usuario y al archivarlo se eliminará de ellas.',
  ROLE_IN_USE_LICENSE_COUNTER:
    'Actualmente, el rol se utiliza en el contador de licencias y al archivarlo se eliminará del mismo.',
  REASON_FOR_REPLACEMENT: 'Motivo del reemplazo',
  QUESTION_TEXT_AFTER_SPLIT: 'Texto de la pregunta después de la división',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    'El flujo de trabajo de aprobación de respuestas de consultas se deshabilitará para el equipo. Las interacciones en estado "Enviado para aprobación" se verán afectadas y volverán al estado "En proceso" para permitir la preparación y respuesta correctas sin aprobación.',
  REVERT_TO_IN_PROGRESS: 'VOLVER A EN PROGRESO',
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Se realizó la división de preguntas mientras la interacción se encontraba en estado {{ SUBMITTED_FOR_APPROVAL }}. Esta acción revierte el estado a {{ IN_PROGRESS }} para permitir la preparación de respuestas para las preguntas recién creadas.',
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Se realizó la acción {{ MERGE_AS_ADDITIONAL_QUESTION }} en la interacción actual, mientras la interacción de destino se encuentra en estado {{ SUBMITTED_FOR_APPROVAL }}. Esta acción revierte el estado a {{ IN_PROGRESS }} para permitir la preparación de respuestas para las preguntas recién creadas.',
  ENQUIRY_ANSWER_APPROVAL_TITLE:
    'Establecer la aprobación de la respuesta de la consulta para los equipos seleccionados',
  APPROVAL_ASSIGN_TO: 'Asignación de aprobación de Enq.',
  APPROVAL_ASSIGN_BY: 'Aprobación de la Enq. asignada por',
  APPROVAL_ASSIGNMENTS: 'Asignaciones de aprobación de enc.',
  APPROVAL_ASSIGNMENTS_TO: 'Asignaciones de aprobación de Enq. a',
  APPROVAL_ASSIGNMENTS_BY: 'Asignaciones de aprobación de Enq. por',
  My_pending_approvals: 'Mis aprobaciones pendientes',
  RULE: 'Regla',
  RULES: 'Normas',
  FIELD: 'Campo',
  CRITERIA: 'Criterios',
  NUMBER: 'Número',
  MIN: 'Mínimo',
  MAX: 'Máximo',
  ADD_GROUP: 'Agregar grupo',
  DELETE_GROUP: 'Eliminar grupo',
  VALID_FROM_TO: 'Válido desde/hasta',
  NEW_INQ: 'Nueva pregunta',
  NOT_APPROVING_INQUIRIES: 'No aprobar consultas',
};
