import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { Ajax } from 'app/common/ajax';
import { BHistory } from './interaction';
import { BInquirer } from '../inquirer/inquirer';
import { BDMMessage } from '../message/message';
import { BUser } from '../user/user';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { MergeReason } from './interaction-merge.service';

type FinalizationResult = 'fwd_to_ext' | 'answer' | 'submit_for_approval' | 'submit_to_inbox';
export type FinalizationResponse = {
  success: boolean;
  result?: FinalizationResult;
  error?: string;
};
export type LastMergeInfo = { mergedIntoId: number; mergeReason: MergeReason };

@Injectable({ providedIn: 'root' })
export class InteractionsService {
  private readonly INTERACTIONS_URL: string;
  private readonly LOCKS_URL: string;
  private readonly SPLIT_URL: string;
  private readonly TOPICS_URL: string;
  private readonly PRIORITIES_URL: string;
  private readonly TEXTS_URL: string;
  private readonly HISTORY_URL: string;
  private readonly PROCESS_PENDING_CHANGES_URL: string;

  responseKeepAliveLock = new Subject<InteractionsService.Lock>();
  getLeftBarHistory$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    resolver: UrlResolverService,
    private matDialog: MatDialog
  ) {
    this.INTERACTIONS_URL = resolver.apiUrl('interactions');
    this.LOCKS_URL = resolver.misApiUrlForPath('/interactions/locks');
    this.SPLIT_URL = resolver.misApiUrlForPath('/interactions/splits');
    this.TOPICS_URL = resolver.misApiUrlForPath('/interactions/topics');
    this.PRIORITIES_URL = resolver.misApiUrlForPath('/interactions/priorities');
    this.TEXTS_URL = resolver.misApiUrlForPath('/interactions/texts');
    this.HISTORY_URL = resolver.misApiUrlForPath('/interactions/history/');
    this.PROCESS_PENDING_CHANGES_URL = resolver.misApiUrlForPath(
      '/interactions/process-pending-changes'
    );
  }

  newDraft(): Observable<number> {
    return this.http.post<number>(this.INTERACTIONS_URL, undefined);
  }

  finalize(
    id: number,
    goal?: 'fwd_to_ext' | 'answer' | 'answer-no-email' | 'submit_for_approval'
  ): Observable<FinalizationResponse> {
    return this.http.put<FinalizationResponse>(this.buildURI(id, true), { goal: goal });
  }

  split(data: {
    inquiryId: number;
    extractText: string;
    remainingText: string;
    inNewInteraction: boolean;
    copyAnswerToNewInteraction: boolean;
    productId: number;
    categoryId: number;
    topicId: number;
    adrRelated: boolean;
    offLabel: boolean;
    productQualityComplain: boolean;
  }): Observable<string> {
    return this.http.post<string>(this.SPLIT_URL, data);
  }

  createFollowUpAttemptHistory(interactionId: number, channel: string): Observable<void> {
    return this.http.post<void>(this.HISTORY_URL + interactionId, { channel });
  }

  getHistory(id: number, intCommHistory: boolean): Observable<InteractionsService.History> {
    const url = `${this.HISTORY_URL + id}${intCommHistory ? '?intCommHistory' : ''}`;
    return this.http.get<InteractionsService.History>(url, Ajax.X_NO_LOADING_OPTIONS).pipe(
      map((r) => {
        r.history = BHistory.fromRestArray(r.history);
        r.inquirers = BInquirer.fromRestArray(r.inquirers);
        return r;
      })
    );
  }

  getLeftBarHistory(interactionId: number): Observable<BHistory[]> {
    return this.http.get<BHistory[]>(`${this.HISTORY_URL}${interactionId}?leftBarHistory`).pipe(
      map((history) => {
        return BHistory.fromRestArray(history);
      })
    );
  }

  getHistoryMessage(historyId: string): Observable<BDMMessage | null> {
    return this.http.get<BDMMessage | null>(`${this.HISTORY_URL}${historyId}/message`).pipe(
      map((message) => {
        return BDMMessage.fromRest(message);
      })
    );
  }

  getLastMergeInfo(interactionId: number): Observable<LastMergeInfo> {
    return this.http.get<LastMergeInfo>(`${this.HISTORY_URL}${interactionId}?lastMergeInfo`);
  }

  deleteDraft(id: number): Observable<string> {
    return this.http.delete<string>(this.buildURI(id, true));
  }

  deleteInquiry(
    isDraft: boolean,
    interactionId: number,
    inquiryId: number,
    isConfirmed: boolean
  ): Observable<string | { newInteractionTeamName: string }> {
    const options = { body: { isConfirmed } };
    return this.http.delete<string>(
      this.buildURI(interactionId, isDraft) + '?inquiryId=' + inquiryId,
      options
    );
  }

  setEvent(id: number, isDraft: boolean, eventId: number): Observable<string> {
    return this.http.post<string>(this.buildURI(id, isDraft), { eventId: eventId });
  }

  setPriority(id: number, isDraft: boolean, priority: string): Observable<string> {
    return this.http.post<string>(this.PRIORITIES_URL, {
      id: id,
      isDraft: isDraft,
      priority: priority,
    });
  }

  setTopic(data: {
    ids?: number[];
    inquiryId?: number;
    isDraft: boolean;
    productId: number;
    categoryId: number;
    topicId: number;
  }): Observable<string | object> {
    return this.http.post<string | object>(this.TOPICS_URL, data).pipe(
      map((response) => {
        if (response && response['message']) {
          GenericDialogComponent.showMessage(this.matDialog, response['message']);
        }
        return response;
      })
    );
  }

  updateText(isDraft: boolean, answerDraftId: number, text: string): Observable<string> {
    return this.http.post<string>(this.TEXTS_URL, {
      isDraft: isDraft,
      answerDraftId: answerDraftId,
      text: text,
    });
  }

  keepAlive(id: number, forceUnlock?: boolean): Observable<InteractionsService.Lock> {
    return this.http
      .post<InteractionsService.Lock>(
        this.LOCKS_URL,
        { id: id, forceUnlock: forceUnlock },
        Ajax.X_NO_LOADING_OPTIONS
      )
      .pipe(
        tap((resp) => {
          this.responseKeepAliveLock.next(resp);
        })
      );
  }

  isInteractionLockedByAnotherUser(
    interaction: InteractionsService.Lock,
    loggedInUser: BUser
  ): boolean {
    return Boolean(
      interaction.lockedBy && loggedInUser && loggedInUser.pk() !== interaction.lockedBy.id
    );
  }

  processPendingChanges(interactionId: number): Observable<void> {
    return this.http.post<void>(`${this.PROCESS_PENDING_CHANGES_URL}/${interactionId}`, {});
  }

  private buildURI(id: number, isDraft: boolean, operation?: string): string {
    return `${this.INTERACTIONS_URL}${isDraft ? '/drafts' : ''}/${id}${
      operation ? '/' + operation : ''
    }`;
  }
}

export namespace InteractionsService {
  export type Lock = {
    id: number;
    lockedBy: {
      id: number;
      firstName: string;
      lastName: string;
    };
    lockedTs: string;
    editedTs: string;
  };

  export type History = {
    history: BHistory[];
    inquirers: BInquirer[];
  };
}
